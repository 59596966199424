export default {
    store: {
        namespaced: true,
        state: {
            configuracoes: {},
            modulos: [],
            definidasGerais: [],
            modulosComConfiguracoes: [],
            usuariosConfigurados: [],
            grupos: [],
        },
        mutations: {
            salvarConfiguracoes(state, configuracoes) {
                state.configuracoes = configuracoes;
            },
            salvarModulos(state, modulos) {
                state.modulos = modulos;
            },
            salvarDefinidasGerais(state, definidasGerais) {
                state.definidasGerais = definidasGerais;
            },
            salvarUsuarios(state, usuarios) {
                state.usuariosConfigurados = usuarios;
            },
            salvarGrupos(state, grupos) {
                state.grupos = grupos;
            },
            redefinirTodasPermissoesDosUsuarios(state, { tipoDesconto, modoDesconto }) {
                state.usuariosConfigurados.forEach(a => {
                    a.TIPODESCONTO = tipoDesconto;
                    a.MODODESCONTO = modoDesconto;
                    a.MODULOS = new Set();
                    a.ACESSOINICIO = '';
                    a.ACESSOFIM = '';
                    a.DIASSEMANA = new Set();
                });
            },
            executarAlteracaoDePermissoes(state, fn) {
                state.usuariosConfigurados.forEach(u => fn(u));
            },
            salvarModulosComConfiguracoes(state, modulos) {
                state.modulosComConfiguracoes = modulos;
            },
        },
        actions: {
            salvarConfiguracoes({ commit }, configuracoes) {
                commit('salvarConfiguracoes', configuracoes);
            },
            salvarDefinidasGerais({ commit }, definidasGerais) {
                commit('salvarDefinidasGerais', definidasGerais);
            },
            salvarUsuarios({ commit }, usuarios) {
                commit('salvarUsuarios', usuarios);
            },
            salvarGrupos({ commit }, grupos) {
                commit('salvarGrupos', grupos);
            },
            redefinirTodasPermissoesDosUsuarios({ commit }, opcoes) {
                commit('redefinirTodasPermissoesDosUsuarios', opcoes)
            },
            executarAlteracaoDePermissoes({ commit }, fn) {
                commit('executarAlteracaoDePermissoes', fn);
            },
            salvarModulos({ commit }, modulos) {
                commit('salvarModulos', modulos);
            },
            salvarModulosComConfiguracoes({ commit }, modulos) {
                commit('salvarModulosComConfiguracoes', modulos);
            }
        }
    }
}
