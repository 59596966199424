import geral from "./geral.js";
import formatacao from "./formatacao.js";
import sessao from "./sessao.js";
import { saveAs } from "file-saver";

function gerarPDF(dados, parceiro) {
	const camposDoParceiroNecessarios = [
		'DESCRICAO',
		'ENDERECO',
		'CEP',
		'CIDADE',
		'BAIRRO',
		'EMAIL',
		'FONE1',
		'ESTADO',
		'CPFCGC'
	];
	geral.prevenirQueValoresDeUmObjetoSejamUndefinedDadoUmaLista(
		parceiro, camposDoParceiroNecessarios
	);

	Date.prototype.addDias = function (dias) {
		this.setDate(this.getDate() + dias)
	}
	const data = new Date();
	const dataFormatada = formatacao.formatarFieldToData(data);

	const dataVencimento = new Date();
	dataVencimento.addDias(10);
	const dataVencimentoFormatada = formatacao.formatarFieldToData(dataVencimento);

	let doc = new jsPDF();
	const dadosPDF = sessao.getItemSessao("PDF")
	const nomeEmpresa = dadosPDF.NOMEFANTASIA
	const enderecoEmpresa = (`${dadosPDF.TIPOLAGRADOURO} ${dadosPDF.LOGRADOURO}`)
	const telefoneEmpresa = dadosPDF.FONE

	let tipoMovimento;
	if (dados.modoOrcamento === 'F')
		tipoMovimento = 'Pedido N°'
	else
		tipoMovimento = 'Orcamento N°'

	function monetario(valor) {
		return 'R$' + valor.toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
	}

	doc.setFontSize(20);
	doc.text(nomeEmpresa, 15, 15,).setFontSize(12).setFont('bold');
	doc.text("Válido até: " + dataVencimentoFormatada, 156, 15).setFont('normal').setTextColor(105, 105, 105);
	doc.text(enderecoEmpresa, 15, 20,).setFontSize(12);
	doc.text("Telefone: " + telefoneEmpresa, 15, 25,).setFontSize(22).setTextColor(0, 0, 0);
	doc.text(tipoMovimento + dados.ordemOrcamento, 15, 45).setFontSize(12).setTextColor(105, 105, 105);
	doc.text(15, 55, 'Cliente: ' + parceiro.DESCRICAO);
	doc.text(15, 60, 'Endereço: ' + parceiro.ENDERECO);
	doc.text(15, 65, 'CEP: ' + parceiro.CEP);
	doc.text(90, 65, 'Cidade: ' + parceiro.CIDADE);
	doc.text(156, 65, 'Bairro: ' + parceiro.BAIRRO);
	doc.text(15, 70, 'E-MAIL: ');
	doc.text(90, 70, 'Telefone: ' + parceiro.FONE1);
	doc.text(156, 70, 'Estado: ' + parceiro.ESTADO);
	doc.text(15, 75, 'CPF / CNPJ: ' + parceiro.CPFCGC);
	doc.text(90, 75, 'RG / Insc. Estadual: ').setTextColor(0, 0, 0).setFontSize(18);

	const table = $('.itens_table_desc').clone();
	table.find('tr th:nth-child(9), tr td:nth-child(9), tr.cancelado').remove();

	const res = doc.autoTableHtmlToJson(table.get(0));
	doc.text("Itens", 100, 90).setFontSize(16);
	doc.autoTable({
		columns: res.columns,
		body: res.data,
		startY: 95,
		theme: 'grid',
		columnStyles: {
			minCellWidth: 100,
			6: { fontStyle: 'bold' }
		},
		headStyles: {
			fillColor: [0, 0, 0]
		},
	});
	// const obs = $('.observacao-input').val();
	doc.autoTable({
		theme: 'grid',
		showHead: 'firstPage',
		pageBreak: 'avoid',
		margin: { bottom: 30 },
		columnStyles: {
		},
		headStyles: {
			fillColor: [0, 0, 0]
		},
		head: [
			['Observações'],
		],
		body: [
			[dados.observacao],
		],
	});

	doc.autoTable({
		theme: 'grid',
		tableWidth: 80,
		showHead: 'firstPage',
		pageBreak: 'avoid',
		margin: { left: 116 },

		columnStyles: {
			0: { cellWidth: 30, fontStyle: 'bold' },
			1: { halign: 'right', fontStyle: 'bold' }
		},
		headStyles: {
			fillColor: [0, 0, 0]
		},
		head: [
			['Valor Total', '']
		],
		body: [
			['Total: ', monetario(dados.totalBruto)],
			['SubTotal: ', monetario(dados.subTotal)],
			['Desconto: ', monetario(dados.desconto)],
			['Total Líquido: ', monetario(dados.totalLiquido)],
		],
	});

	const height = doc.internal.pageSize.getHeight();
	doc.autoTable({
		theme: 'plain',
		startY: height - 30,
		columnStyles: { 0: { textColor: [105, 105, 105] } },
		body: [
			['____________________________________________________________________________________________'],
			['Criado em ' + dataFormatada + ' por ' + dados.vendedor.DESCRICAOFUNCIONARIO + '.'],
		],
	});

	if (geral.verificarSeAPlataformaEhMobile()) {
		doc.save('orcamento_os.pdf')
	} else {
		doc.output('dataurlnewwindow');
	}

}

function gerarNotaFiscal(dados, parceiro) {
	Date.prototype.addDias = function (dias) {
		this.setDate(this.getDate() + dias)
	};
	const data = new Date();
	const dataFormatada = formatacao.formatarFieldToData(data);

	const dataVencimento = new Date();
	dataVencimento.addDias(10);
	const dataVencimentoFormatada = formatacao.formatarFieldToData(dataVencimento);

	$("#body").append(`
        <table id="tabela-itensNF" style="display: none">
            <tbody class="corpo-tabela-itensNF">

            </tbody>
        </table>
    `);

	const itens = sessao.getItemSessao('ITENS');
	for (let i = 0; i < itens.length; i++) {
		const objItens = itens[i];
		const classeCancelado = (objItens['CANCELADO'] === itens.ITEM_CANCELADO) ? 'class="cancelado"' : '';


		$(".corpo-tabela-itensNF").append(`
            <tr ${classeCancelado} class="item">
                <td>${objItens.DESCPRODUTO}</br>${objItens.QTDPRODUTO}x     ${objItens.VALORITEM}</td>
                <td>${objItens.VALORTOTAL}</td>
            </tr>
        `)
	}
	$(".corpo-tabela-itensNF").append(`
        <tr>
            <td>———————————</td>
        </tr>
    `)

	const itensC = $(".corpo-tabela-itensNF > tr.item").length;

	const obs = "Obs.: " + $('.observacao-input').val();
	const tObs = parseFloat(obs.length);

	const com = 285 + (itensC * 40 + 15) + (tObs / 40 * 10);
	const doc = new jsPDF('p', 'mm', [264, com]);
	const nomeEmpresa = sessao.getItemSessao("NOMEEMPRESA")


	doc.setFontSize(16);
	doc.text(nomeEmpresa, 2, 10,).setFont('bold');
	doc.text("Orçamento N°" + dados.ordemOrcamento, 2, 20).setFontSize(12).setTextColor(105, 105, 105);
	doc.text(2, 25, 'Cliente: ' + parceiro.DESCRICAO);
	doc.text("Válido até: " + dataVencimentoFormatada, 2, 30).setFont('normal').setTextColor(0, 0, 0);

	doc.text("Qtd X Descr.", 2, 40);
	doc.text("Total", 81, 40);
	doc.text("—————————————————————", 2, 43).setFontSize(11).setTextColor(105, 105, 105);

	$('#tabela-itensNF').find('tr.cancelado').remove();
	doc.autoTable({
		html: '#tabela-itensNF',
		theme: 'plain',
		startY: 44,
		tableWidth: 88,
		margin: { left: 2 },
		showHead: 'firstPage',
		pageBreak: 'avoid',
		styles: {
			cellPadding: { top: 2, bottom: 0, left: 0, right: 0 },
		},
		columnStyles: {
			0: { cellWidth: 68 },
			1: { halign: 'right', fontStyle: 'bold' }
		},
	});

	let totalBruto = formatacao.replaceCaracteresEmNumeros(dados.TOTALBRUTO);
	let totalLiquido = formatacao.replaceCaracteresEmNumeros(dados.TOTALLIQUIDO);
	let desconto = totalBruto - totalLiquido;
	let descontoFormatado = desconto.toFixed(2).toString().replace(".", ",");
	doc.autoTable({
		theme: 'plain',
		tableWidth: 88,
		margin: { left: 2 },
		showHead: 'firstPage',
		pageBreak: 'avoid',
		styles: {
			cellPadding: 0,
		},
		columnStyles: {
			0: { fontStyle: 'bold' },
			1: { halign: 'right', fontStyle: 'bold' }
		},
		body: [
			['Total: ', dados.totalBruto],
			['Desconto: ', descontoFormatado],
			['Total Líquido: ', dados.totalLiquido]
		],
	});
	doc.autoTable({
		theme: 'plain',
		tableWidth: 88,
		margin: { left: 2 },
		showHead: 'firstPage',
		pageBreak: 'avoid',
		styles: {
			cellPadding: 0,
		},
		body: [
			['—————————————————————————'],
			[obs],
			['—————————————————————————'],
			['Criado em ' + dataFormatada + ' por ' + sessao.getItemSessao('DESCRICAOFUNCIONARIO') + '.']
		],
		didParseCell: function (data) {
			const rows = data.table.body;
			if (data.row.index === rows.length - 1) {
				data.cell.styles.textColor = [115, 115, 115];
				data.cell.styles.fontSize = 10;
			}
		}
	});
	doc.save("orcamento_NF");
}

function salvarConteudoRelatorio(fileContents, fileName) {
	const base = fileContents.indexOf('base64') + 7;
	const arquivo = fileContents.substring(base, fileContents.length);

	try {
		let binaryData = window.atob(arquivo);
		let binaryArray = new Uint8Array(binaryData.length);
		for (let i = 0; i < binaryData.length; i++) {
			binaryArray[i] = binaryData.charCodeAt(i);
		}
		let blob = new Blob([binaryArray], { type: "application/pdf" });
		saveAs(blob, fileName)
		return true
	} catch (error) {
		return { erro: error}
	}
}

async function imprimirConteudo(dados) {
	try {
		const data = formatacao.formatarDataToField(new Date());
		const nomeRelatorio = `Relatorio_${data}.pdf`
	
		if (geral.verificarSeAPlataformaEhMobile()) {
			return salvarConteudoRelatorio(dados, nomeRelatorio);
		} else {
			const pdfWindow = window.open();
			pdfWindow.document.write('<iframe width="100%" height="100%" src="' + dados + '"></iframe>');
			pdfWindow.document.title = nomeRelatorio;

			pdfWindow.addEventListener('load', function () {
				pdfWindow.focus();
			});
			return true
		}
	} catch (error) {
		return { erro: error }
	}
}

export default {
	gerarPDF,
	gerarNotaFiscal,
	imprimirConteudo
}
