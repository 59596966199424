<template>
    <ModalInformativoTemplate @fecharModal="$store.commit('fecharModalPositivo')">
        <template #header>
            <slot name="titulo">
                Sucesso!
            </slot>
        </template>

        <template #icon>check_circle</template>
        <template #message>
            <slot name="mensagem"></slot>
        </template>
    </ModalInformativoTemplate>
</template>

<script>
import ModalInformativoTemplate from '../modais/templates/ModalInformativoTemplate.vue'

/**
 * Modal com intuito de disponibilizar uma mensagem de sucesso ao usuário
 * 
 * ATENÇÃO: ModalSucesso é um componente global. É possível ter acesso a ele pelo Store
 * da aplicação, sem necessidade de importá-lo.
 */
export default {
    components: {
        ModalInformativoTemplate
    },
}
</script>
