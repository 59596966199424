<template>
    <footer id="footer">
        <h2>&copy; {{anoDinamico}} OG1 Systems</h2>
    </footer>
</template>

<script>
export default {
    computed: {
        anoDinamico() {
            const data = new Date();
            const ano = data.getFullYear();

            return ano;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/vars';

#footer {
    // position: absolute;
    // bottom: 0;
    z-index: -1;

    box-sizing: border-box;
    width: 100%;
    background-color: $background;
    padding: 1rem;
    text-align: center;
    font-size: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
