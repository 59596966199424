<template>
    <div class="sidebar__root">
        <GeralSidebar :nav="rotas" :modulo="false" />

        <section class="sidebar__page-conteiner">
            <HeaderModulos />

            <RouterView />

            <FooterOG1 />
        </section>
    </div>
</template>

<script>
import FooterOG1 from "@/components/FooterOG1";
import GeralSidebar from "@/components/GeralSidebar.vue";
import HeaderModulos from "@/components/HeaderModulos.vue";

import geral from "../../lib/utils/geral.js";
import varsPermissoes from "@/lib/vars/permissoes";

export default {
    components: {
        FooterOG1,
        GeralSidebar,
        HeaderModulos
    },
    data() {
        return {
            usuario: {},
            mobile: false,
            pastaImagem: '',
            rotas: [
                {
                    rota: '/modulos',
                    icon: 'web',
                    descricao: 'Módulos',
                },
                {
                    rota: '/consultar-clientes',
                    icon: 'person_search',
                    descricao: 'Consultar clientes'
                },
            ],
        }
    },
    beforeMount() {
        const permissoes = this.$store.state.empresa.PERMISSOES;

        if (permissoes.admin && permissoes.admin.find(p => p.codigo === varsPermissoes.ADMIN_CONFIGURAR_USUARIOS)) {
            this.rotas.push(
                {
                    rota: '/enviar-imagens',
                    icon: 'drive_folder_upload',
                    descricao: 'Enviar pasta de imagens',
                },
                {
                    rota: '/configuracoes-web',
                    icon: 'settings',
                    descricao: 'Configurações'
                })
        }
    },
    async mounted() {
        this.$store.dispatch("iniciarCarregamento");
        this.mobile = geral.verificarSeAPlataformaEhMobile();

        try {
            const usuario = await this.$axios.get("/modulos/retornarUsuario");
            this.usuario = usuario.data;
        } catch (error) {
            error = error.response.data.erro;

            if (error === "USER_NON_AUTH")
                this.$router.replace({
                    name: "login",
                    query: { auth: "user" },
                });
            else
                this.$router.replace({
                    name: "login",
                    query: { auth: "empr" },
                });
        } finally {
            this.$store.dispatch("pararCarregamento");
        }
    },
    methods: {
        togglePerfil() {
            this.$store.dispatch('sidebar/abrirSidebar');
        },
        ocultarEnvio() {
            const invalidos = [null, undefined, ''];
            if (invalidos.includes(this.pastaImagem)) {
                return false
            } else {
                return true
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/vars';

.perfil,
.rotas-conteiner,
.opcoes-mobile {
    padding: .5rem;
}

.perfil {
    border-bottom: 2px solid #212121;
}

.opcoes-mobile {
    border-top: 2px solid #212121;
}

.perfil {
    &-background {
        position: fixed;
        z-index: 1;

        width: 100%;
        height: 100vh;
    }

    &-conteiner {
        position: relative;

        height: inherit;

        background-color: #0a0a0a;
        color: white;
    }

    display: flex;
    align-items: center;

    & .icone-perfil {
        font-size: 4.5rem;
    }

    & .modulos-menu-branco {
        position: absolute;
        right: 2rem;

        font-size: 1.8rem;
    }

    &__usuario {
        width: 60%;

        & span {
            display: block;
            padding: .2rem;
        }
    }
}

.rotas-conteiner {
    height: 75%;

    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.perfil-botao {
    box-sizing: border-box;
    width: 100%;
    padding: .5rem;
    border-radius: 4px;

    font-size: 1rem;
    color: #fff;
    text-decoration: none;

    display: flex;
    align-items: center;
    gap: .4rem;

    & i {
        font-size: 1.5rem;
    }

    &:hover {
        background-color: #424242;
    }
}

.pagina-atual {
    background-color: #424242;
}

@media screen and (min-width: 700px) {
    .perfil {
        &-conteiner {
            width: 60%;
        }
    }
}

@media screen and (min-width: 1024px) {
    .perfil {
        &-background {
            position: static;
        }

        &-background,
        &-conteiner {
            width: 350px;
        }

        & .modulos-menu-branco {
            display: none;
        }

        &__usuario {
            width: 100%;
        }
    }
}
</style>
