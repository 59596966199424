<template>
    <ModalTemplate>
        <template #title>Pesquisar Cliente</template>

        <template #body>
            <form action="#" class="pesquisa-parceiro">
                <fieldset class="pesquisa-parceiro__input-pesquisa">
                    <input placeholder="Pesquisar..." v-model="pesquisa">
                    <span id="alerta-pesquisa-parceiro">{{ mensagemErro }}</span>
                </fieldset>


                <BaseBotaoAcao
                    @handleClick="pesquisarClientes">
                    PESQUISAR
                </BaseBotaoAcao>
            </form>

            <div class="lista-clientes-conteiner">
                <ul id="lista-clientes">
                    <li
                        v-for="cliente in clientes"
                        :key="cliente.CODIGO"
                        @click="preencherCliente(cliente.CODIGO)">
                        <span class="codigo">
                            {{ cliente.CODIGO }}
                        </span>
                        <span class="descricao">
                            {{ cliente.DESCRICAO }}
                        </span>
                    </li>
                </ul>
            </div>
        </template>
    </ModalTemplate>
</template>

<script>
import ModalTemplate from '@/components/modais/templates/ModalTemplate.vue';
import BaseBotaoAcao from '../BaseBotaoAcao.vue';
import req from '@/lib/utils/request';

export default {
    components: {
        ModalTemplate,
        BaseBotaoAcao
    },
    props: ['filtro', 'tipoRelatorio'],
    data() {
        return {
            pesquisa: '',
            clientes: [],
            mensagemErro: '',
        }
    },
    methods: {
        async pesquisarClientes() {
            try {
                this.mensagemErro = '';

                const { data } = await this.$axios.get('/relatorios/retornarDadosPesquisa', {
                    params: {
                        PESQUISA: this.pesquisa,
                        CODIGORELATORIO: this.tipoRelatorio,
                        MASCARA: this.filtro.MASCARA,
                        POSICAO: this.filtro.POSICAO,
                    }
                });
                if (data.mensagem)
                    this.mensagemErro = data.mensagem;
                else
                    this.clientes = data;
            } catch (error) {
                this.mensagemErro = req.tratarError(error);
            }
        },
        preencherCliente(codigo) {
            this.$emit('preencherCliente', codigo);
            this.$emit('fecharModal');
        }
    }
}
</script>

<style lang="scss" scoped>
    #lista-clientes {
        & li {
            min-height: 0;
            padding: 15px 0;
        }
    }
</style>
