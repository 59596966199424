<template>
    <div v-show="filtro.VISIVEL === 'T'">
        <label>{{ filtro.CAMPODESCRICAO }}</label>
        <div class="input">
            <input
                :type="filtro.TIPO"
                v-model="valorEscolhido"
                ref="input"
            />
            <i
                class="material-icons-sharp lupa-pesquisar"
                v-if="filtro.COMBO === 'T'"
                @click="abrirPesquisarCliente">
                search
            </i>
        </div>

        <ModalClienteRelatorio
            v-if="pesquisarClienteAberto"
            :filtro="filtro"
            :tipoRelatorio="tipoRelatorio"
            @preencherCliente="preencherInput"
            @fecharModal="fecharPesquisarCliente"
        />
    </div>
</template>

<script>
    import ModalClienteRelatorio from '../modais/ModalClienteRelatorio.vue';

    export default {
        props: ['filtro', 'tipoRelatorio', 'index'],
        emits: ['pegarValor'],
        components: {
            ModalClienteRelatorio
        },
        data() {
            return {
                pesquisarClienteAberto: false,
            }
        },
        computed: {
            valorEscolhido: {
                get() {
                    return this.filtro.VALOR;
                },
                set(novoValor) {
                    this.$emit('pegarValor', this.filtro, novoValor);
                }
            }
        },
        methods: {
            abrirPesquisarCliente() {
                this.pesquisarClienteAberto = true;
            },
            fecharPesquisarCliente() {
                this.pesquisarClienteAberto = false;
            },
            preencherInput(escolhido) {
                this.$emit('pegarValor', this.filtro, escolhido);
            },
            verificarSeInputEhReadonly() {
                const input = this.$refs.input;

                if (this.filtro.FIXO === 'readonly') {
                    input.readOnly = true;
                } else {
                    input.readOnly = false;
                }
            },
        },
        watch: {
            filtro() {
                this.verificarSeInputEhReadonly();
            }
        },
        mounted() {
            this.verificarSeInputEhReadonly();
        }
    }
</script>

<style scoped>
    .input {
        position: relative;
        min-width: fit-content;
    }

    .input:deep(input) {
        height: 34px;
    }

    .input:deep(input:not([type="date"])) {
        width: 100%;
    }

    .input:deep(input:disabled) {
        background-color: rgba(239, 239, 239, 0.3);
        color: rgb(84, 84, 84);
    }

    .input:deep(.lupa-pesquisar) {
        position: absolute;
        right: 0rem;
        top: 0rem;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        border: 2px solid #555555;
        padding: 3px;
        background-color: #363636;
        color: white;
    }

    .input:deep(.lupa-pesquisar:hover) {
        background-color: #363636c4;
    }
</style>
