import app from '@/main';
import { lazyLoad } from '../lib/utils/router';

export default [
    {
        path: '/admin',
        name: 'admin',
        redirect: {
            name: 'homeAdmin'
        },
        children: [
            {
                path: 'home',
                name: 'homeAdmin',
                component: lazyLoad('admin/HomeAdmin')
            },
            {
                path: 'editar-empresa',
                name: 'editarEmpresa',
                component: lazyLoad('admin/EditarEmpresa'),
                redirect: { name: 'informacoesEditarEmpresa' },
                children: [
                    {
                        path: '',
                        name: 'informacoesEditarEmpresa',
                        component: lazyLoad('admin/InformacoesEmpresa'),
                    },
                    {
                        path: 'permissoes',
                        name: 'permissoesEditarEmpresa',
                        component: lazyLoad('admin/PermissoesEmpresa')
                    },
                    {
                        path: 'modulos',
                        name: 'modulosEditarEmpresa',
                        component: lazyLoad('admin/Modulos'),
                    },
                    {
                        path: 'dashboards',
                        name: 'dashboardsEditarEmpresa',
                        component: lazyLoad('admin/TodosDashboards'),
                    },
                ]
            },
            {
                path: 'editar-empresa/dashboards/:id',
                name: 'editarDashboard',
                component: lazyLoad('admin/EditarDashboard')
            }
        ],
        beforeEnter: async (to) => {
            try {
                await app.axiosInstance.get('/admin/retornarAdmin');
            } catch (error) {
                error = error.response.data.erro;
                if (to.name !== 'login')
                    return {
                        path: '/admin/login'
                    }
            }
        }
    },
    {
        path: '/admin/login',
        name: 'loginAdmin',
        component: lazyLoad('admin/LoginAdmin')
    }
]
