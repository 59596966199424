import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router/index';
import Vue3TouchEvents from "vue3-touch-events"

import axios from 'axios';

import '@/assets/styles/style.scss';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(Vue3TouchEvents);

const axiosInstance = axios.create({
    baseURL: (process.env.NODE_ENV === 'production')
        ? `https://${process.env.VUE_APP_IP_API}:${process.env.VUE_APP_PORTA_API}/api`
        : `/api`,
    withCredentials: true,
    timeout: 50000,
});
app.config.globalProperties.$axios = axiosInstance;

app.mount('#app');

export default { axiosInstance }
