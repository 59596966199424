<template>
    <div class="sidebar__root">
        <GeralSidebar 
            :nav="[
                {
                    rota: '/relatorios',
                    icon: 'description',
                    descricao: 'Relatórios',
                }
            ]"
        />

        <div class="sidebar__page-conteiner">
            <HeaderModulos />
        
            <BaseCentro>
                <BaseConteiner>
                    <template #title.h1>Consultar Relatórios</template>
        
                    <template #body>
                        <div class="relatorios">
                            <form class="campos-consulta">
                                <label for="pesquisa-orcamento">Tipo de Relatório</label>
                                <select v-model="tipoRelatorio" id="tipo-pesquisa" name="tipo">
                                    <option value="">Selecione...</option>
                                    <option
                                        v-for="opcao in $store.state.relatorios.opcoes"
                                        :key="opcao.CODIGORELATORIO"
                                        :value="opcao.CODIGORELATORIO">
                                        {{ opcao.TITULO }}
                                    </option>
                                </select>
        
                                <div
                                    class="relatorios__filtros"
                                    v-for="(opcao, i) in $store.state.relatorios.opcoes"
                                    :key="i"
                                    v-show="tipoRelatorio === opcao.CODIGORELATORIO">
                                    <InputFiltro
                                        v-for="(filtro, j) in opcao.FILTROS"
                                        :key="j"
                                        :filtro="filtro"
                                        :tipoRelatorio="opcao.CODIGORELATORIO"
                                        :index="j"
                                        @pegarValor="pegarValorInputFiltro"
                                    />
                                </div>
                            </form>
                        </div>
        
                        <div class="relatorios-footer">
                            <BaseBotaoAcao @handleClick="imprimirRelatorio">Gerar Relatório</BaseBotaoAcao>
                        </div>
                    </template>
                </BaseConteiner>
            </BaseCentro>
        
            <FooterOG1 />
        </div>
    </div>
</template>

<script>
import HeaderModulos from '@/components/HeaderModulos.vue';
import BaseCentro from '@/components/BaseCentro.vue';
import BaseConteiner from '../../components/BaseConteiner.vue';
import BaseBotaoAcao from '@/components/BaseBotaoAcao.vue';
import TabelaPaginada from '../../components/TabelaPaginada.vue';
import ModalDetalhesOrcamento from '../../components/consultaOrcamentos/modais/ModalDetalhesOrcamento.vue';
import InputFiltro from '../../components/relatorio/InputFiltro.vue';
import FooterOG1 from '@/components/FooterOG1.vue';
import GeralSidebar from '@/components/GeralSidebar.vue';

import geradorRelatorio from '../../lib/utils/geradorRelatorio.js';
import req from '../../lib/utils/request.js'

export default {
    components: {
        HeaderModulos,
        BaseCentro,
        BaseConteiner,
        BaseBotaoAcao,
        TabelaPaginada,
        ModalDetalhesOrcamento,
        InputFiltro,
        FooterOG1,
        GeralSidebar
    },
    data() {
        return {
            tipoRelatorio: "",
            modalVisualizarRelatorioAberto: false,
        }
    },
    methods: {
        pegarValorInputFiltro(filtro, valor) {
            filtro.VALOR = valor;
        },
        async imprimirRelatorio() {
            try {
                this.$store.dispatch('iniciarCarregamento');

                if (!this.tipoRelatorio)
                    throw new Error('Selecione um tipo de relatório');

                const filtroAtual =
                    this.$store.state.relatorios.opcoes.find(el => el.CODIGORELATORIO === this.tipoRelatorio);
                
                const camposPreenchidos = filtroAtual.FILTROS.reduce((acc, current) => {
                    if (current.VALOR)
                        return acc + 1;
                    else
                        return acc;
                }, 0);
                if (!camposPreenchidos)
                    throw new Error('Consulta não pode ser vazia!');

                const args = [];
                filtroAtual.FILTROS.forEach(obj => {
                    const campo = { ...obj };

                    let valor = campo.VALOR;
                    if (!valor && (campo.VISIVEL === 'T' && campo.OBRIGATORIO === 'T'))
                        throw new Error(`Campo "${campo.CAMPODESCRICAO}" é obrigatório`);

                    if ((valor && campo.TIPO === 'date'))
                        valor = valor.split('-').reverse().join('/');
                    if (campo.TIPO === 'date')
                        campo.TIPO = 'D';

                    args.push({
                        ABREFILTRO: campo.ABREFILTRO,
                        NOMETABELA: campo.NOMETABELA,
                        CAMPO: campo.CAMPO,
                        OPERADOR: campo.OPERADOR,
                        FECHAFILTRO: campo.FECHAFILTRO,
                        CONDICAO: campo.CONDICAO,
                        TIPO: campo.TIPO,
                        VALOR: valor,
                    });
                });

                const { data } = await this.$axios.post('/relatorios/imprimirRelatorio', {
                    WHERE: args,
                    CODIGORELATORIO: filtroAtual.CODIGORELATORIO,
                });

                await geradorRelatorio.imprimirConteudo(data.RELATORIO);

                filtroAtual.FILTROS.forEach(obj => {
                    obj.VALOR = '';
                });
            } catch (error) {
                this.$store.dispatch('abrirModalAlerta', req.tratarError(error));
            } finally {
                this.$store.dispatch('pararCarregamento');
            }
        }
    },
    async mounted() {
        try {
            this.$store.dispatch('iniciarCarregamento');

            if (!this.$store.state.relatorios.opcoes.length) {
                await this.$store.dispatch('relatorios/listarRelatorios');
                await this.$store.dispatch('relatorios/preencherListaDeFiltros');
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                this.$store.dispatch(
                    'abrirModalAlerta',
                    'Parece que a sua empresa não está configurada corretamente para gerar relatórios...'
                )
                this.$router.push({ name: 'homeTiraPedido' });
            }else if (error.response.status === 500){
                this.$store.dispatch(
                    'abrirModalAlerta',
                    'Houve um erro ao tentar exibir seus relatórios, pode ser necessário verificar o integração.'
                )
            }
        } finally {
            this.$store.dispatch('pararCarregamento');
        }
    }
}
</script>

<style lang="scss" scoped>
.relatorios {
    &-footer {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
    }
}

</style>
