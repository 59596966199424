<template>
    <ModalInformativoTemplate @fecharModal="$store.commit('fecharModalAlerta')">
        <template #header>
            <slot name="titulo">
                Ops... algo deu errado
            </slot>
        </template>

        <template #icon>warning_amber</template>
        <template #message>
            <slot name="mensagem"></slot>
        </template>
    </ModalInformativoTemplate>
</template>

<script>
    import ModalInformativoTemplate from '../modais/templates/ModalInformativoTemplate.vue'

    export default {
        components: {
            ModalInformativoTemplate
        },
    }
</script>
