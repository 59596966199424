<template>
    <div class="conteiner-dropdown" tabindex="-1" @blur="() => aberto = false">
        <div
            class="botao"
            @click.left="lidarComClique($event, 'esquerda')"
            @contextmenu="lidarComClique($event, 'direita')"
        >
            <slot name="botao"></slot>
        </div>

        <article class="dropdown" v-if="aberto">
            <slot></slot>
        </article>
    </div>
</template>

<script>
export default {
    emits: [ 'mainAction' ],
    props: {
        dropdownOnRightClick: {
            type: Boolean,
            default: false,
        },
        mainActionOnLeftClick: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            aberto: false,
        }
    },
    methods: {
        lidarComClique(ev, lado) {
            if (lado === 'esquerda' && this.mainActionOnLeftClick && this.dropdownOnRightClick) {
                this.$emit('mainAction');
            } else if (lado === 'esquerda' && this.mainActionOnLeftClick && !this.dropdownOnRightClick) {
                console.warn('Ação principal de dropdown será ignorada sem o clique direito do mouse ativado');
            }

            if (this.dropdownOnRightClick && lado === 'direita') {
                ev.preventDefault();
                this.aberto = !this.aberto;
            } else if (!this.dropdownOnRightClick && lado === 'esquerda') {
                this.aberto = !this.aberto;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.conteiner-dropdown {
    position: relative;

    padding: 0;
    margin: 0;
}

.botao {
    display: flex;
    align-items: center;
}

.dropdown {
    position: absolute;
    right: .5rem;
    z-index: 1;

    margin: 5px;
    min-width: 200px;

    background-color: #f0f0f0;
    box-shadow: 0 4px 4px 0 #0000001f;

    display: flex;
    flex-direction: column;
    gap: .5rem;
}
</style>
