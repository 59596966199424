import dashboard from "@/lib/utils/dashboard";

const setup = () => {
    return {
        criando: {
            dialeto: '',
            grafico: {
                id: '',
                sql: '',
                tipo: 'bar',
                camposRequest: '',
                chaveLegendas: '',
                chaveDados: '',
                chaveAgrupar: '',
                request: null,
                quantidadeDados: 0,
                titulo: '',
                cores: [],
                exibirLegendas: true,
                yAxis: false,
                cache: {
                    quantidadeDados: 0,
                }
            },
            tabelaDetalhes: {
                ativa: false,
                qualQuery: 'mesmo',
                sql: '',
                itens: [],
                request: null,
                cache: {
                    request: null,
                }
            },
            macro: {
                ativa: false,
                amostra: 0,
                sql: '',
                variavel: '',
                request: null,
            },
            bloco: {
                id: '',
                sql: '',
                chaveDado: '',
                cor: '',
                request: null,
                camposRequest: [],
            }
        }
    }
};

export default {
    store: {
        namespaced: true,
        state: setup(),
        mutations: {
            resetar(state) {
                Object.assign(state, setup());
            }
        },
        actions: {
            resetar({ commit }) {
                commit('resetar');
            }
        },
        getters: {
            optionsCriandoGrafico(state) {
                const g = state.criando.grafico;

                let indexAxis = {};
                if (g.tipo === 'bar')
                    indexAxis = {
                        indexAxis: g.yAxis ? 'y' : 'x',
                    };

                return {
                    ...indexAxis,
                    plugins: {
                        legend: {
                            display: g.exibirLegendas,
                        },
                        tooltip: {
                            callbacks: {
                                footer: dashboard.computarPorcetagemTooltip
                            }
                        }
                    }
                }
            },
            itensCriandoTabela(state) {
                const tabela = state.criando.tabelaDetalhes;
                const grafico = state.criando.grafico;
                if (tabela.qualQuery === 'outro' && tabela.request && tabela.request.length)
                    return tabela.request;
                else if (tabela.qualQuery === 'mesmo' && grafico.request && grafico.request.length)
                    return state.criando.grafico.request;
                else
                    return [
                        {
                            LABEL: 'a',
                            DATA: 1
                        },
                        {
                            LABEL: 'b',
                            DATA: 2
                        },
                        {
                            LABEL: 'c',
                            DATA: 3
                        },
                        {
                            LABEL: 'd',
                            DATA: 4
                        },
                    ];
            }
        }
    }
}
