export default {
    mudarCasoPromocional(item) {
        const valoresPromocionais = () => {
            item.UNITARIO = item.PRECOPROMOCAO;
            item.UNITARIOFIXO = item.PRECOPROMOCAO;
        };
    
        const calcularDescontoPromocional = () => {
            item.DESCONTOREAL = (item.PRECOVENDA - item.UNITARIOFIXO);
            item.DESCONTOPORCENTO = (item.DESCONTOREAL / item.PRECOVENDA) * 100;
        };
    
        if (item.PRECOPROMOCAO && item.QUANTIDADE >= item.QTDPROMOCAO) {
            valoresPromocionais();
            calcularDescontoPromocional();
        }
    },

    mesclarAlmoxarifados(itens, saida) {
        itens.forEach(el => {
            if (!saida.ALMOXARIFADOS) 
                saida.ALMOXARIFADOS = {};
            saida.ALMOXARIFADOS[el.CODIGOALMOXARIFADO] = {
                quantidade: el.QUANTIDADE,
                total: el.TOTAL,
            };
        });
        return saida;
    }
}