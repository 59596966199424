<template>
    <section class="conteiner-views">
        <header class="conteiner-views__header">
            <div>
                <h2>
                    <slot name="title.h2"></slot>
                </h2>

                <h1>
                    <slot name="title.h1"></slot>
                </h1>
            </div>

            <div class="voltar">
                <BaseBotaoAcao v-if="modo === 'botaoVoltar'" @click="voltar">
                    <template #icon>arrow_back_ios</template>
                    Voltar
                </BaseBotaoAcao>
            </div>

            <slot name="other"></slot>
        </header>

        <hr class="divider" />

        <main class="conteiner-views__body">
            <slot name="body"></slot>

            <div class="mensagem-erro">
                <slot name="mensagem"></slot>
            </div>
        </main>
    </section>
</template>

<script>
import BaseBotaoAcao from './BaseBotaoAcao.vue';

export default {
    components: {
        BaseBotaoAcao,
        BaseBotaoAcao
    },
    props: {
        modo: {
            type: String,
            required: false
        },
    },
    emits: ['voltar'],
    methods: {
        voltar() {
            if (this.voltar) {
                this.$emit('voltar')
            }
        }
    }
}

</script>

<style lang="scss" scoped>
.conteiner-views {
    box-sizing: border-box;

    width: 100%;
    height: fit-content;
    padding: 10px;
    border: 4px solid #f0f0f0;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    display: flex;
    flex-direction: column;

    &__header {
        box-sizing: border-box;
        min-height: 48px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        & h1 {
            font-size: 1.4rem;
            text-transform: uppercase;
            font-weight: 500;
        }

        & h2 {
            font-size: 1.2rem;
            font-weight: 600;
        }
    }

    & .divider {
        width: 100%;
        height: 2px;
        margin: 0;
        border: none;
        background-color: #f0eded;
    }

    &__body {
        margin-top: .5rem;
    }
}

.voltar {
    cursor: pointer;
}
</style>
