<template>
    <section class="header__usuario" id="usuario">
        <a class="header__usuario-icone" @click.left="toggleModalPerfil()">
            <i class="material-icons-sharp">account_circle</i>
            <span id="username">{{ nomeUsuario }}</span>
            <i class="material-icons-sharp">arrow_drop_down</i>
        </a>

        <div
            class="header__usuario-modal-conteiner"
            id="usuario-modal"
            v-if="opcoesAberta"
            @click="opcoesAberta = false">
            <div class="header__usuario-modal" @click.stop>
                <div class="header__usuario-modal-triangulo"></div>

                <RouterLink
                    v-for="(link) in rotas"
                    :key="link.rota"
                    :to="link.rota"
                    class="header__usuario-modal-deslogar links-mobile"
                    @click="opcoesAberta = false">
                    <i class="material-icons-sharp">{{ link.icon }}</i>
                    <span>{{ link.descricao }}</span>
                </RouterLink>

                <div
                    v-if="!vendas"
                    class="header__usuario-modal-deslogar"
                    @click.left="irParaServicos()">
                    <i class="material-icons-sharp">arrow_back</i>
                    <span>Voltar para serviços</span>
                </div>
                <div
                    v-else
                    class="header__usuario-modal-deslogar"
                    @click.left="deslogar()">
                    <i class="material-icons-sharp">logout</i>
                    <span>Sair</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import sessao from '@/lib/utils/sessao';
import geral from '@/lib/utils/geral';

export default {
    props: ['rotas', 'vendas'],
    data() {
        return {
            opcoesAberta: false,
        }
    },
    computed: {
        nomeUsuario() {
            if (document.cookie.length) {
                return geral.getValorCookie('usuario');
            } else {
                const usuario = sessao.getItemSessao('VENDEDOR');
                return usuario.DESCRICAOUSUARIO;
            }
        }
    },
    methods: {
        toggleModalPerfil() {
            this.opcoesAberta = !this.opcoesAberta;
        },
        checarSeAberto(ev) {
            console.log(ev);
        },
        irParaServicos() {
            this.$router.push('/modulos');
        },
        deslogar() {
            this.$axios.get('/vendas/logout')
                .then(() => {
                    this.$router.push('/nao-autenticado')
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
}
</script>
