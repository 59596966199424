import store from '@/store';
import app from '../../main';
import geral from './geral';

export function lazyLoad(view) {
    return () => import(`@/views/${view}.vue`);
}

export async function mudarModulo(toPath) {
    try {
        let MODULO = '';
        if (toPath)
            MODULO = toPath.path.slice(1);

            const proximaBarraIndex = MODULO.indexOf('/');
            if (proximaBarraIndex !== -1)
                MODULO = MODULO.slice(0, proximaBarraIndex);
        await app.axiosInstance.post('/modulos/mudarModulo', { MODULO });
    } catch (error) {
        console.log(error);
    }
}

export function tratarFaltaDeAcesso(toPath, erro) {
    if (erro === 'SERV_FORB_ERR') {
        if (geral.getValorCookie('modulo') === toPath.name) {
            mudarModulo();
        }
        return {
            path: '/404'
        }
    } else if (erro === 'USER_NOT_SYNC') {
        if (toPath.name === 'modulos') {
            store.dispatch('abrirModalAlerta', 'Parece que este usuário ainda não está sincronizado. Algumas funcionalidades estarão indisponíveis.');
            store.dispatch('pararCarregamento');
            return true;
        } else {
            return {
                path: '/404'
            }
        }
    } else if (erro === 'USER_NON_AUTH' && toPath !== 'login') {
        return {
            path: '/login',
            query: {
                auth: 'user'
            }
        }
    } else if (erro === 'EMPR_NON_AUTH' && toPath !== 'login') {
        return {
            path: '/login',
            query: {
                auth: 'empr'
            }
        }
    } else {
        return {
            path: '/404'
        }
    }
}

export async function verificarAcessoModulo(modulo) {
    await app.axiosInstance.get('/modulos/verificarAcesso', {
        params: {
            MODULO: modulo
        }
    })
}
