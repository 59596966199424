<template>
    <div class="sidebar__root">
        <GeralSidebar :nav="nav" />

        <div class="sidebar__page-conteiner">
            <HeaderModulos />

            <main>
                <router-view />

                <ModalLocalizacao v-if="modalLocalizacaoAberto" @fecharModal="fecharModalLocalicao">
                    <template v-slot:titulo>
                        <h2>Ative a Localização</h2>
                    </template>
                    <template v-slot:mensagem>
                        <p>Permitir a localização é necessário para o funcionamento do sistema.</p>
                    </template>
                </ModalLocalizacao>

                <ModalConfirmarAcao v-if="modalPedirLocalizacaoAberto" @confirmarAcao="pedirLocalizacao"
                    @fecharModal="negarLocalizacao">
                    <template #mensagem>Deseja ativar a localização?</template>
                    <template #desistir>NEGAR</template>
                </ModalConfirmarAcao>
            </main>

            <FooterOG1 />
        </div>
    </div>
</template>

<script>
import GeralSidebar from '@/components/GeralSidebar.vue';
import HeaderModulos from '@/components/HeaderModulos';
import FooterOG1 from '@/components/FooterOG1';
import ModalLocalizacao from '@/components/modais/ModalLocalizacao';
import ModalConfirmarAcao from '@/components/modais/ModalConfirmarAcao.vue';

import sessao from '@/lib/utils/sessao';

export default {
    components: {
        GeralSidebar,
        HeaderModulos,
        FooterOG1,
        ModalLocalizacao,
        ModalConfirmarAcao
    },
    data() {
        return {
            modalLocalizacaoAberto: false,
            modalPedirLocalizacaoAberto: false,
            modoSideBar: ''
        }
    },
    computed: {
        nav() {
            const baseNav = [
                {
                    rota: '/tirapedido',
                    icon: 'content_paste',
                    descricao: 'Criar Orçamento'
                },
                {
                    rota: '/tirapedido/consultar-clientes',
                    icon: 'person_search',
                    descricao: 'Consultar Clientes'
                },
                {
                    rota: '/tirapedido/consultar-materiais',
                    icon: 'inventory_2',
                    descricao: 'Consultar Materiais'
                },
                {
                    rota: '/tirapedido/consultar-orcamentos',
                    icon: 'content_paste_search',
                    descricao: 'Consultar Orçamentos'
                },
            ];

            if (this.$route.path.startsWith('/tirapedido/rotas')) {
                return baseNav.slice(4);
            } else {
                return baseNav;
            }
        }
    },
    async mounted() {
        this.$store.dispatch('pararCarregamento');
        if (localStorage.getItem('localizacaoPermitida') === null) {
            this.abrirModalPedirLocalizacao()
        }
    },
    methods: {
        abrirModalLocalizacao() {
            this.modalLocalizacaoAberto = true;
        },
        fecharModalLocalicao() {
            this.modalLocalizacaoAberto = false;
        },
        fecharModalPerdirLocalizacao() {
            this.modalPedirLocalizacaoAberto = false
        },

        abrirModalPedirLocalizacao() {
            this.modalPedirLocalizacaoAberto = true
        },
        async pedirLocalizacao() {
            try {
                await sessao.solicitarLocalizacao()
            } catch (error) {
                this.abrirModalLocalizacao()
            }
            this.fecharModalPerdirLocalizacao()
        },
        negarLocalizacao() {
            const exigirLocalizacao = sessao.getItemSessao('LOCALIZACAO')
            this.fecharModalPerdirLocalizacao()
            localStorage.setItem('localizacaoPermitida', 'false');
            if (exigirLocalizacao === 'T') {
                this.abrirModalLocalizacao()
            }
        }


    },
}
</script>
