import Vendas from '@/views/vendaCliente/Vendas.vue';

import app from '@/main';
import store from '@/store';
import { lazyLoad } from '../lib/utils/router';

const autenticarUsuarioVendas = async () => {
    try {
        const { data } = await app.axiosInstance.get('/vendas/retornarUsuario');
        store.dispatch('orcamento/salvarSaldoNegativo', data.EMPRESA_PROVEDORA.SALDONEGATIVO);
        store.dispatch('orcamento/salvarModoIndustria', data.EMPRESA_PROVEDORA.MODOINDUSTRIA);
        store.dispatch('orcamento/salvarFormaIndustria', data.EMPRESA_PROVEDORA.FORMAINDUSTRIA);
        store.dispatch('orcamento/salvarTipoIndustria', data.EMPRESA_PROVEDORA.TIPOINDUSTRIA);
    } catch (error) {
        return {
            name: 'avisoNaoAutenticado'
        }
    }
}

export default [
    {
        path: '/vendas',
        name: 'homeVendas',
        component: Vendas,
        redirect: {
            name: 'categoriaVendas'
        },
        children: [
            {
                path: 'produtos/:categoria*',
                name: 'categoriaVendas',
                component: lazyLoad('vendaCliente/Categorias')
            },
            {
                path: 'produtos/pesquisa/:pesquisa',
                name: 'pesquisaProdutos',
                component: lazyLoad('vendaCliente/Pesquisa')
            }
        ],
        beforeEnter: autenticarUsuarioVendas,
    },
    {
        path: '/vendas/pagamento',
        name: 'pagamentoVendas',
        component: lazyLoad('vendaCliente/Pagamento'),
        beforeEnter: autenticarUsuarioVendas,
    },
    {
        path: '/vendas/login',
        redirect: {
            name: 'avisoAutenticado'
        }
    },
    {
        path: '/vendas/login/:cnpj',
        redirect: {
            name: 'avisoAutenticado'
        }
    },
    {
        path: '/vendas/login/:cnpj/:codigo',
        name: 'vendasLogin',
        component: lazyLoad('vendaCliente/LoginVendas'),
    },
    {
        path: '/nao-autenticado',
        name: 'avisoNaoAutenticado',
        component: lazyLoad('vendaCliente/AvisoAutenticacao')
    },
]
