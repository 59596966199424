<template>
    <div>
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
div {
    margin: .5rem;
}

@media screen and (min-width: 900px) {
    div {
        width: 80%;
        margin: .5rem auto;
    }
}
</style>
