<template>
    <div style="position: relative; padding-bottom: 50px;">
        <HeaderVendas :barra-pesquisa="true" />

        <div v-if="$store.state.vendas.headerFixa" class="placeholder"></div>

        <main class="vendas-main">
            <RouterView />
        </main>

        <Transition name="effect">
            <Carrinho v-if="this.$store.state.vendas.carrinhoAberto" />
        </Transition>

        <FooterOG1 />
    </div>
</template>

<script>
import HeaderVendas from "@/components/vendas/HeaderVendas.vue";
import Carrinho from "./Carrinho.vue";
import FooterOG1 from "@/components/FooterOG1.vue";

export default {
    components: {
        HeaderVendas,
        Carrinho,
        FooterOG1
    },
    mounted() {
        this.$store.dispatch('vendas/restaurarCarrinho');
    }
};
</script>

<style lang="scss" scoped>
.vendas-main {
    max-width: 1024px;
    margin: .5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.placeholder {
    width: 100%;
    height: 80px;
}

@media screen and (min-width: 768px) {
    .vendas-main {
        margin: 2rem;
    }
}

@media screen and (min-width: 1200px) {
    .vendas-main {
        margin: 1rem auto;
    }
}
</style>
