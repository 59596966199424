<template>
    <article class="card">
        <div :class="['info', card.tipo === 'carregando' ? 'carregando' : '']">
            <i :class="['material-icons-sharp']">{{ icone }}</i>
        </div>
        <span class="mensagem">
            {{ card.mensagem }}
        </span>
        <i class="material-icons-sharp fechar" @click="removerCardInformativo(card.id)">close</i>
    </article>
</template>

<script>
export default {
    props: {
        posicao: {
            type: Number
        }
    },
    computed: {
        card() {
            return this.$store.state.cardsInformativos[this.posicao];
        },
        icone() {
            switch(this.card.tipo) {
                case 'erro':
                    return 'error';
                case 'sucesso':
                    return 'check_circle';
                case 'carregando':
                    return 'refresh';
            }
        }
    },
    mounted() {
        const removerCard = () => this.removerCardInformativo(this.card?.id);
        const timer = setInterval(() => {
            removerCard();
            clearTimeout(timer);
        }, 60000);
    },
    methods: {
        removerCardInformativo(id) {
            if (id) {
                this.$store.dispatch('removerCardInformativo', id);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.card {
    position: relative;

    box-sizing: border-box;
    width: 280px;
    height: 60px;
    padding: .5rem;
    border-radius: 4px;
    box-shadow: 0px 3px 10px 0px #0000007c;
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;

    & > .info {
        & > i {
            font-size: 2rem;
        }
    }

    & .fechar {
        padding: .3rem;
        position: absolute;
        top: 0;
        right: 0;

        font-size: 1.2rem;
        opacity: .7;

        &:hover {
            opacity: 1;
        }
    }

    & .mensagem {
        width: 80%;
        font-size: .9rem;
    }
}

.carregando {
    animation: roda 1s ease-in-out forwards infinite;
}

@keyframes roda {
    from {
        transform: rotate(90deg);
    }
    to {
        transform: rotate(440deg);
    }
}
</style>