import Relatorios from '@/views/relatorios/Relatorios.vue';

import app from '@/main';
import {
    verificarAcessoModulo,
    mudarModulo,
    tratarFaltaDeAcesso
} from '@/lib/utils/router';
import store from '@/store';

export default [
    {
        path: '/relatorios',
        name: 'relatorios',
        component: Relatorios,
        beforeEnter: async (to) => {
            try {
                const { data } = await app.axiosInstance.get('/modulos/retornarUsuario');
                if (data)
                    store.dispatch('empresa/setEmpresa', data.USUARIO);
                
                await verificarAcessoModulo('relatorios');
                await mudarModulo(to);
            } catch (error) {
                error = error.response.data.erro;
                return tratarFaltaDeAcesso(to, error);
            }
        }
    }
]
