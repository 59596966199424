import dotenv from 'dotenv';
dotenv.config();

import formatacao from "./formatacao.js";

const API = process.env.NODE_ENV === 'production'
    ? `https://${process.env.VUE_APP_IP_API}:${process.env.VUE_APP_PORTA_API}/api`
    : `/api`

function addEmSessao(chave, valor) {
    if (valor instanceof Object) {
        sessionStorage.setItem(chave, JSON.stringify(valor));
    } else {
        sessionStorage.setItem(chave, valor);
    }
}

function addEmStorage(chave, valor) {
    if (valor instanceof Object) {
        localStorage.setItem(chave, JSON.stringify(valor));
    } else {
        localStorage.setItem(chave, valor);
    }
}


function incrementarEmChaveSessao(chave, valor) {
    const objSessao = getItemSessao(chave);

    if (objSessao instanceof Array) {
        objSessao.push(valor);

        addEmSessao(chave, objSessao);
    } else {
        let list = [];
        list.push(objSessao);
        list.push(valor);
        list = JSON.stringify(list);

        sessionStorage[chave] = list;
    }
}

function getItemSessao(chave) {
    const formatado = formatacao.transformaUndefinedParaVazio(sessionStorage[chave]);
    if (formatado.startsWith('{') || formatado.startsWith('[')) {
        return JSON.parse(formatado);
    } else {
        return formatado;
    }
}

function getItemStorage(chave) {
    const formatado = formatacao.transformaUndefinedParaVazio(localStorage[chave]);
    if (formatado.startsWith('{') || formatado.startsWith('[')) {
        return JSON.parse(formatado);
    } else {
        return formatado;
    }
}

function getToken() {
    return getItemSessao('TOKEN');
}

function getUrlDatabaseCliente() {
    return `https://${process.env.VUE_APP_IP_API}:${process.env.VUE_APP_PORTA_API}/api`;
}

function removerDaSessao(chave) {
    sessionStorage.removeItem(chave);
}

function removerDoStorage(chave) {
    localStorage.removeItem(chave);
}

function retornarNAtributosDaSessao(...atributos) {
    const valoresDaSessao = atributos.map((attr) => {
        return getItemSessao(attr);
    });
    return valoresDaSessao;
}

function getViewport() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    return {
        width: vw,
        height: vh
    }
}

function getPlataforma() {
    return navigator.userAgent;
}

function solicitarLocalizacao() {
    const exigirLocalizacao = getItemSessao('LOCALIZACAO')
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude
                localStorage.setItem('localizacaoPermitida', 'true');
                resolve({ latitude: latitude, longitude: longitude })

            }, function (error) {
                if (error.code === error.PERMISSION_DENIED) {
                    localStorage.setItem('localizacaoPermitida', 'false');
                    if (exigirLocalizacao === 'T') {
                        reject({ message: 'A localização é necessária para utilizar o Sistema.' })
                    } else if (exigirLocalizacao === 'F') {
                        resolve({ latitude: null, longitude: null })
                    } else {
                        resolve({ latitude: 0, longitude: 0 })
                    }
                }
            });
        } else {
            reject({ message: 'A geolocalização não é suportada pelo navegador' })
        }
    })
}



export default {
    API,
    addEmSessao,
    incrementarEmChaveSessao,
    getItemSessao,
    getToken,
    getUrlDatabaseCliente,
    removerDaSessao,
    retornarNAtributosDaSessao,
    getViewport,
    getPlataforma,
    solicitarLocalizacao,
    addEmStorage,
    removerDoStorage,
    getItemStorage
}
