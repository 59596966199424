<template>
    <div class="dummy-sidebar" :class="classeSidebar">
        <aside class="sidebar" :class="classeSidebar">
            <div>
                <section class="secao-cliente">
                    <div class="perfil">
                        <i class="material-icons-sharp icone-perfil" :class="opacidadeTexto">account_circle</i>
                        <div class="perfil-usuario" :class="opacidadeTexto">
                            <span>{{ $store.state.empresa.NOMEFANTASIA ? $store.state.empresa.NOMEFANTASIA : '' }}</span>
                            <span>{{ $store.state.empresa.CNPJ ? $store.state.empresa.CNPJ : '' }}</span>
                        </div>
                        <i class="material-icons-sharp menu-toggle" @click="$store.dispatch('sidebar/toggleSidebar')">menu</i>
                    </div>

                    <RouterLink to="/modulos" class="sidebar-nav-link" v-if="modulo" @click="$store.dispatch('sidebar/toggleSidebar')">
                        <i class="material-icons-sharp">arrow_back</i>
                        <span :class="opacidadeTexto">Voltar Para Serviços</span>
                    </RouterLink>
                </section>

                <section class="sidebar-nav">
                    <RouterLink
                        class="sidebar-nav-link"
                        v-for="link in nav"
                        :key="link.rota"
                        :class="link.rota === $route.path ? 'pagina-atual' : ''"
                        :to="link.rota"
                        :title="link.descricao"
                        @click="fecharSidebar">
                        <i class="material-icons-sharp">{{ link.icon }}</i>
                        <span :class="opacidadeTexto">{{ link.descricao }}</span>
                    </RouterLink>
                </section>

                <section class="outras-config">
                    <div class="sidebar-nav-link sair" @click="deslogar">
                        <i class="material-icons-sharp">logout</i>
                        <span :class="opacidadeTexto">Sair</span>
                    </div>
                </section>
            </div>
        </aside>
    </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    props: {
        nav: Array,
        modo: String,
        modulo: {
            type: Boolean,
            default: true
        },
    },
    components: {
        RouterLink
    },
    computed: {
        classeSidebar() {
            return this.$store.state.sidebar.sidebarAberto
                ? 'sidebar-aberto'
                : 'sidebar-fechado';
        },
        opacidadeTexto() {
            return this.$store.state.sidebar.sidebarAberto
                ? 'exibicao-texto'
                : 'retirada-texto';
        }
    },
    // beforeMount() {
    //     if (!this.$store.state.ehMobile)
    //         this.$store.dispatch('sidebar/abrirSidebar');
    //     else
    //         this.$store.dispatch('sidebar/fecharSidebar');

    // },
    methods: {
        deslogar() {
            this.$axios.get("/modulos/logout").then(() => {
                this.$store.dispatch("limparSessao");
                this.$router.push("/login");
            });
        },
        fecharSidebar() {
            if (this.$store.state.ehMobile)
                this.$store.dispatch('sidebar/fecharSidebar');
        }
    }
}
</script>

<style lang="scss" scoped>
.dummy-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 2;

    transition: all .2s linear;
}

.sidebar {
    overflow: hidden;
    position: fixed;
    z-index: 1;

    box-sizing: border-box;
    width: inherit;
    height: inherit;

    background-color: #0a0a0a;
    color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: all .2s linear;

    &-aberto {
        width: 100%;
    }

    &-fechado {
        width: 0;
    }

    & .secao-cliente,
    & .sidebar-nav,
    & .outras-config {
        padding: .5rem;
    }

    & .secao-cliente {
        border-bottom: 2px solid #212121;
    }

    & .outras-config {
        border-top: 2px solid #212121;
    }

    & .sidebar-nav {
        /* height: calc(100vh - 14rem); */

        display: flex;
        flex-direction: column;
        gap: .4rem;
    }

    & .sidebar-nav-link {
        padding: .5rem;
        border-radius: 4px;

        text-decoration: none;
        color: #fff;

        display: flex;
        align-items: center;
        gap: .5rem;

        &:hover {
            background-color: #424242;
        }
    }

    .pagina-atual {
        background-color: #424242;
    }

    & .sair {
        &:hover {
            background-color: #ff1d1dd8;
        }
    }

    & .perfil {
        display: flex;
        align-items: center;
        gap: .5rem;
    }

    & .icone-perfil {
        font-size: 4.5rem;
    }

    .perfil-usuario {
        width: 60%;
        display: flex;
        flex-direction: column;
    }
}

.menu-toggle {
    padding: .5rem;
    font-size: 1.8rem;
}

.exibicao-texto {
    opacity: 0;
    animation: texto-entrar .5s ease-out forwards;
    animation-delay: .2s;
}

.retirada-texto {
    animation: texto-sair .1s ease-out forwards;
}

@keyframes texto-entrar {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes texto-sair {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        display: none;
    }
}

@media screen and (min-width: 700px) {
    .sidebar {
        &-aberto {
            width: 300px;
        }

        &-fechado {
            width: 60px;
        }
    }

    .outras-config {
        display: none;
    }
}

@media screen and (min-width: 1024px) {
    .dummy-sidebar {
        position: static;
    }

    .sidebar-aberto {
        &.dummy-sidebar {
            width: 380px;
        }

    }

    .sidebar-fechado{
        &.dummy-sidebar {
            width: 70px;
        }
    }

    .sidebar {
        position: fixed;

        & .perfil-usuario {
            width: 100%;
        }
    }
}
</style>
