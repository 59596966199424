<template>
    <button
        v-if="!quantidade"
        class="quantidade adicionar-carrinho"
        @click="adicionarAoCarrinho()">
        Adicionar
    </button>

    <div
        v-else
        class="quantidade incrementar-carrinho">
        <span @click="retirarDoCarrinho()">-</span>
        <input v-model="quantidade" @keypress="checarSeNumero" @blur="atualizarQuantidade" />
        <span @click="adicionarAoCarrinho()">+</span>
    </div>
</template>

<script>
import fmt from '@/lib/utils/formatacao';

export default {
    props: ['item', 'saldoNegativo'],
    computed: {
        quantidade: {
            set(valor) { },
            get() {
                return this.$store.getters['vendas/getQuantidade'](this.item.CODIGOMATERIAL);
            }
        },
        estoqueDisponivel() {
            return fmt.replaceCaracteresEmNumeros(this.item.DISPONIVEL);
        }
    },
    methods: {
        adicionarAoCarrinho() {
            try {
                this.checarSeSaldoNegativo(this.quantidade);
                this.$store.dispatch('vendas/adicionarAoCarrinho', this.item);
            } catch (error) {
                this.$store.dispatch('abrirModalAlerta', error.message);
            }
        },
        checarSeSaldoNegativo(el) {
            if (this.saldoNegativo === 'F' && el >= this.estoqueDisponivel) {
                throw new Error('Quantidade não pode ser maior que estoque disponível.');
            }
        },
        checarSeNumero(ev) {
            if (!(/\d/).test(ev.key)) {
                ev.preventDefault();
            }
        },
        atualizarQuantidade(ev) {
            const valor = parseInt(ev.target.value);
            try {
                this.checarSeSaldoNegativo(valor);
            } catch (error) {
                this.$store.dispatch('abrirModalAlerta', error.message);
                this.$store.dispatch('vendas/excluirItemDoCarrinho', this.item.CODIGOMATERIAL);
                return false;
            }

            const quantidade = this.$store.getters['vendas/getQuantidade'](this.item.CODIGOMATERIAL);
            let restante = valor - quantidade;

            if (restante > 0) {
                this.$store.dispatch(
                    'vendas/adicionarListaAoCarrinho',
                    {
                        itens: Array.from({length: restante}, (v, i) => this.item),
                        codigo: this.item.CODIGOMATERIAL,
                    }
                );
            } else if (restante < 0) {
                restante = Math.abs(restante);
                for (let i = 0; i < restante; i++) {
                    this.retirarDoCarrinho();
                }
            } else {
                this.$store.dispatch('vendas/excluirItemDoCarrinho', this.item.CODIGOMATERIAL);
            }
        },
        retirarDoCarrinho() {
            this.$store.dispatch('vendas/retirarDoCarrinho', this.item);
        }
    }
}
</script>

<style lang="scss" scoped>
.quantidade {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    & input {
        max-width: 50px;
        border: none;
        text-align: center;
    }
}

.adicionar-carrinho {
    border: none;
    border-radius: 4px;
}

.incrementar-carrinho {
    user-select: none;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    & span {
        min-width: 25px;
        width: 15%;
        height: 100%;
        border-radius: 4px;
        background-color: #cccccc;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform .2s ease-in-out;
    } 
    & span:active {
        transform: scale(1.2);
    }
}
</style>
