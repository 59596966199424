<template>
    <BaseTabela 
        :itens="itensAtuais ? itensAtuais : []"
        :labels="labels"
        :attrs="attrs"
        :actions="actions"
        :listaImagens="listaImagens"
        :modo="modo"
        @clicarEmRow="clicarEmRow"
    />

    <div v-if="modo === 'orcamento' && Object.keys(itensTabela).length" class="totalGeral_div">
        <span>Total Geral: {{ totalGeral }}</span>
    </div>

    <div class="paginacao-conteiner">
        <Paginate v-show="Object.keys(itensTabela).length" v-model="page" :pageCount="quantidadePaginas" :pageRange="3"
            :clickHandler="getPagina" :prevText="'Ante.'" :nextText="'Prox.'" :containerClass="'paginacao'"
            :prevClass="'pagina-anterior'" :nextClass="'pagina-proxima'">
        </Paginate>
    </div>

    
</template>

<script>
import BaseTabela from './BaseTabela.vue';
import ModalVisualisarImagem from './tiraPedido/itens/modais/ModalVisualisarImagem.vue';
import Paginate from 'vuejs-paginate-next';

import fmt from '@/lib/utils/formatacao';

export default {
    components: {
        BaseTabela,
        Paginate,
        ModalVisualisarImagem
    },
    props: {
        itensTabela: Object,
        labels: Array,
        attrs: Array,
        actions: Array,
        modo: String,
        miniModal: Boolean
    },
    emits: ['clicarEmRow'],
    data() {
        return {
            page: 1,
            quantidadePaginas: 1,
            itensAtuais: [],
            listaImagens: [],
            totalGeral: 0
        }
    },
    methods: {
        getPagina(numPagina) {
            this.page = numPagina;
            this.mudarPagina();
        },
        mudarPagina() {
            this.itensAtuais = this.itensTabela[this.page];
        },
        retornarAtributo(item, attr) {
            if (attr instanceof Array) {
                let atributoComposto = '';

                for (let a of attr) {
                    let naoIndefinido = fmt.transformaUndefinedParaVazio(item[a])
                    atributoComposto += naoIndefinido + ' '
                }
                return atributoComposto;
            } else {
                return fmt.transformaUndefinedParaVazio(item[attr]);
            }
        },
        colocarNumerosADireita(valor) {
            try {
                const formatado = fmt.replaceCaracteresEmNumeros(valor);
                if (valor.includes(',') && !isNaN(formatado)) {
                    return 'numero-a-direita';
                } else if (!isNaN(formatado)) {
                    return 'valor-centrado';
                } else {
                    return '';
                }
            } catch (error) {
                return '';
            }
        },
        clicarEmRow(item) {
            if (this.clicarEmRow) {
                this.$emit('clicarEmRow', item);
            }
        },
   
        async checkImageLinks(links, index) {
            for (let link of links) {
                if (await this.checkImageLink(link)) {
                    this.listaImagens[index] = link;
                    return;
                }
            }
            this.listaImagens[index] = 'https://vendas.og1.inf.br/imagensweb/noImage.jpg';
        },
        checkImageLink(link) {
            return new Promise((resolve) => {
                let img = new Image();
                img.onload = () => {
                    resolve(true)
                };
                img.onerror = () => {
                    resolve(false)
                }
                img.src = link
            })
        },
    },
    watch: {
        itensTabela: {
            handler() {
                this.quantidadePaginas = Object.keys(this.itensTabela).length;
                if (this.quantidadePaginas > 0) this.page = 1;
                this.mudarPagina();
            },
            immediate: true,
        },
        itensAtuais() {
            if (this.itensAtuais && this.modo === 'material') {
                this.itensAtuais.forEach(async (item, index) => {
                    await this.checkImageLinks([item.IMAGEM, item.IMAGEMAUXILIAR], index);
                });
            }else if(this.itensAtuais && this.modo === 'orcamento'){
                this.totalGeral = this.itensAtuais[0].TOTALGERAL
            }
        }
    },
}
</script>

<style lang="scss">
.imgProduto {
    height: 80px;
    width: 80px
}

.tabela-consulta__conteudo tr td {
    vertical-align: middle;
}

.totalGeral_div {
    padding: 10px 10px 0;
    margin-top: 5px;

    span{
        font-weight: bold;
    }
}
</style>