import app from '../main';
import sessao from '../lib/utils/sessao';
import fmt from '@/lib/utils/formatacao';

const store = {
    namespaced: true,
    state: {
        categorias: {},
        carrinho: {},
        carrinhoAberto: false,
        headerFixa: false,
        pesquisa: [],
        descricaoPesquisa: '',
    },
    mutations:{
        salvarCategorias(state, categorias) {
            state.categorias = categorias;
        },
        adicionarAoCarrinho(state, { item, codigoItem }) {
            const codigo = codigoItem ? codigoItem : item.CODIGOMATERIAL;

            if (!state.carrinho[codigo]) {
                state.carrinho[codigo] = [];
            }

            if (item instanceof Array) {
                Array.prototype.push.apply(state.carrinho[codigo], item);
            } else {
                state.carrinho[codigo].push(item);
            }
        },
        retirarDoCarrinho(state, item) {
            const codigo = item.CODIGOMATERIAL;

            state.carrinho[codigo].pop();
            if (!state.carrinho[codigo].length)
                delete state.carrinho[codigo];
        },
        excluirItemDoCarrinho(state, codigo) {
            delete state.carrinho[codigo];
        },
        esvaziarCarrinho(state) {
            for (let codigo in state.carrinho) {
                delete state.carrinho[codigo];
            }
        },
        modificarSessaoCarrinho(state) {
            sessao.addEmSessao('CARRINHO', state.carrinho);
        },
        abrirCarrinho(state) {
            state.carrinhoAberto = true;
        },
        fecharCarrinho(state) {
            state.carrinhoAberto = false;
        },
        fixarHeaderVendas(state, y) {
            if (y > 80)
                state.headerFixa = true;
            else
                state.headerFixa = false;
        },
        salvarPesquisa(state, { itens, descricao }) {
            state.pesquisa = itens;
            state.descricaoPesquisa = descricao;
        }
    },
    actions: {
        async listarCategorias({ commit }) {
            const { data } = await app.axiosInstance.get('/vendas/listarCategorias');
            commit('salvarCategorias', data);
        },
        adicionarAoCarrinho({ commit }, item) {
            commit('adicionarAoCarrinho', { item });
            commit('modificarSessaoCarrinho');
        },
        adicionarListaAoCarrinho({ commit }, { itens, codigo }) {
            commit('adicionarAoCarrinho', { item: itens, codigoItem: codigo });
            commit('modificarSessaoCarrinho');
        },
        retirarDoCarrinho({ commit }, item) {
            commit('retirarDoCarrinho', item);
            commit('modificarSessaoCarrinho')
        },
        excluirItemDoCarrinho({ commit }, codigo) {
            commit('excluirItemDoCarrinho', codigo);
            commit('modificarSessaoCarrinho');
        },
        esvaziarCarrinho({ commit }) {
            commit('esvaziarCarrinho');
            commit('modificarSessaoCarrinho');
        },
        restaurarCarrinho({ dispatch, state }) {
            const carrinhoSessao = sessao.getItemSessao('CARRINHO');

            if (
                !Object.keys(state.carrinho).length &&
                (carrinhoSessao && Object.keys(carrinhoSessao).length)
            ) {
                for (let codigo in carrinhoSessao) {
                    dispatch('adicionarListaAoCarrinho', {
                        itens: carrinhoSessao[codigo],
                        codigo: codigo
                    });
                }
            }
        },
        async pesquisar({ commit }, payload) {
            const { data } = await app.axiosInstance.get('/vendas/listarMateriais', {
                params: {
                    QUERY: payload
                }
            });
            commit('salvarPesquisa', { itens: data, descricao: payload });
        }
    },
    getters: {
        getQuantidade: (state) => (codigo) => {
            if (!state.carrinho[codigo])
                return 0;
            return state.carrinho[codigo].length;
        },
        getPreco: (state, getters) => (codigo) => {
            let preco = state.carrinho[codigo][0].PRECOVENDA;
            preco = fmt.replaceCaracteresEmNumeros(preco);

            return preco;
        },
        getPromocao: (state, getters) => (codigo) => {
            let promocao = state.carrinho[codigo][0].PRECOPROMOCAO;
            promocao = fmt.replaceCaracteresEmNumeros(promocao);

            if (!promocao || promocao === '')
                return null;
            return promocao;
        },
        getQuantidadeCarrinho(state) {
            const quantidade = Object.keys(state.carrinho).reduce((prev, cur) => {
                return prev + state.carrinho[cur].length;
            }, 0);
            return quantidade;
        },
        getSubTotal(state, getters) {
            return Object.keys(state.carrinho).reduce((total, codigo) => {
                const quantidade = getters.getQuantidade(codigo);
                const promocao = getters.getPromocao(codigo);

                if (promocao) {
                    return total + (promocao * quantidade);
                } else {
                    const preco = getters.getPreco(codigo);
                    return total + (preco * quantidade);
                }
            }, 0);
        },
        getTotalBruto(state, getters) {
            return Object.keys(state.carrinho).reduce((total, codigo) => {
                const quantidade = getters.getQuantidade(codigo);
                    const preco = getters.getPreco(codigo);
                    return total + (preco * quantidade);
                // }
            }, 0);
        }
    }
}

export default {
    store,
}
