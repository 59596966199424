import { createRouter, createWebHistory } from 'vue-router';

import Login from '@/views/Login.vue';
import Error404 from '../views/Error404.vue';

import rotasAdmin from './admin';
import rotasModulos from './modulos';
import rotasTiraPedido from './tiraPedido';
import rotasRelatorios from './relatorios';
import rotasGerarLinkVendas from './gerarLinkVendas';
import rotasDashboard from './dashboard';
import rotasVendas from './vendas';

import app from '../main';
import { tratarFaltaDeAcesso } from '../lib/utils/router';


export default createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            beforeEnter: async (to) => {
                try {
                    await app.axiosInstance.get('/modulos/retornarUsuario');
                    return { name: 'modulos' }
                } catch (error) {
                    return tratarFaltaDeAcesso(to, error.response.data.erro);
                }
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login
            // beforeEnter se encontra no próprio componente
        },
        ...rotasAdmin,
        ...rotasModulos,
        ...rotasTiraPedido,
        ...rotasRelatorios,
        ...rotasGerarLinkVendas,
        ...rotasVendas,
        ...rotasDashboard,
        {
            path: '/404',
            name: 'notFound',
            component: Error404
        },
        {
            path: '/:catchAll(.*)',
            name: 'catchAll',
            component: Error404
        }
    ]
});
