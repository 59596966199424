<template>
    <div>
        <div class="fundo" @click="fecharCarrinho()"></div>

        <div class="conteiner-carrinho">
            <header class="header-carrinho">
                <div class="botao-voltar" @click="fecharCarrinho">
                    <i class="material-symbols-outlined">arrow_back_ios</i>
                    <h1>Voltar</h1>
                </div>

                <div
                    v-if="this.$store.getters['vendas/getQuantidadeCarrinho']"
                    class="esvaziar-carrinho"
                    @click="esvaziarCarrinho">
                    <span>Esvaziar Carrinho</span>
                </div>
            </header>

            <div class="carrinho" v-if="Object.keys($store.state.vendas.carrinho).length">
                <section class="itens-carrinho">
                    <ItemCarrinho
                        v-for="(v, nome) in $store.state.vendas.carrinho"
                        :key="v"
                        :codigoItem="nome"
                    />
                </section>

                <section class="finalizar-venda">
                    <p class="finalizar-venda__avisos">
                        Cupom, formas de pgto., etc. serão exibidos na próxima
                        página.
                    </p>

                    <span v-if="['F',null,undefined].includes(modoIndustria)" class="finalizar-venda__subtotal">
                        <p>Subtotal:</p>
                        <p>{{ subTotal }}</p>
                    </span>

                    <RouterLink to="/vendas/pagamento" class="finalizar-venda__botao">
                        <span class="material-symbols-outlined">
                            shopping_cart
                        </span>

                        <span> Ir para o fechamento </span>
                    </RouterLink>
                </section>
            </div>


            <section v-else class="carrinho-vazio">
                <i class="material-symbols-outlined">shopping_cart_checkout</i>
                <strong>Seu carrinho está vazio</strong>

                <button @click="fecharCarrinho">Iniciar Compra</button>
            </section>
        </div>
    </div>
</template>

<script>
import ItemCarrinho from "@/components/vendas/ItemCarrinho.vue";
import Pagamento from "./Pagamento.vue";
import sessao from "@/lib/utils/sessao"

import fmt from "@/lib/utils/formatacao";

export default {
    components: {
        ItemCarrinho,
        Pagamento
    },
    data() {
        return {
            abrePagamento: false,
            countOrder: 0,

        };
    },
    computed: {
        subTotal() {
            let subTotal = this.$store.getters['vendas/getSubTotal'];
            subTotal = fmt.retornarNumberEmMonetario(subTotal);
            return `R$ ${subTotal}`;
        },
        modoIndustria() {
            return sessao.getItemSessao('MODOINDUSTRIA');
        }
    },
    methods: {
        fecharCarrinho() {
            this.$store.commit('vendas/fecharCarrinho');
        },
        esvaziarCarrinho() {
            this.$store.dispatch('vendas/esvaziarCarrinho');
        }
    },
};
</script>

<style lang="scss" scoped>
.fundo {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    display: none;
}

.conteiner-carrinho {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;

    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    background-color: #f1f1f1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    overflow-x: hidden;
    scroll-behavior: smooth;

    display: flex;
    flex-direction: column;
}

.header-carrinho {
    position: sticky;
    top: 0;
    z-index: 1;

    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    border-bottom: .1em #d3d3d3 solid;

    background-color: white;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.botao-voltar {
    cursor: pointer;

    font-size: 1.2rem;
    color: #495057;
    font-weight: 500;

    display: flex;
    align-items: center;
}

.esvaziar-carrinho {
    width: 120px;
    height: 100%;
    border-radius: 2px;
    font-size: .8rem;

    display: flex;
    align-items: center;
    justify-content: center;


    &:hover {
        background-color: #ff3b3b;
    }
}

.carrinho {
    height: 100%;

    display: grid;
    grid-template-rows: auto 120px;
}

.finalizar-venda {
    position: sticky;
    bottom: 0;

    box-sizing: border-box;
    width: 100vw;
    height: 120px;
    padding: .5em;
    border: 1px #d3d3d3 solid;
    box-sizing: border-box;

    background-color: #ffffff;
    color: black;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__avisos {
        margin: 0;
        margin-bottom: 1.5em;
        font-size: 11.5px;

        display: flex;
        justify-content: center;
    }

    &__subtotal {
        width: 100%;
        margin-bottom: .5em;

        display: flex;
        flex-direction: row;
        flex-grow: 0;
        justify-content: space-between;

        :last-child {
            font-weight: 600;
        }
    }

    &__botao {
        width: 97%;
        padding: .5em;
        border: 1px #d3d3d3 solid;
        border-radius: 4px;

        text-decoration: none;
        background-color: #ffffff;
        color: black;
        background-color: #d3d3d3;
        font-size: 1.2em;
        font-family: 'Roboto', sans-serif;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        &:hover {
            cursor: pointer;
            outline: none;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            transition: all .3s ease-in-out;
            background-color: black;
            color: white;
        }
    }
}

.carrinho-vazio {
    margin: auto;
    font-size: 1.3em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & i {
        font-size: 5rem;
    }

    & button {
        width: 17em;
        height: 2.5em;
        padding: .5em;
        margin-top: 1em;
        border-radius: 4px;
        border: .1rem solid #d3d3d3;

        background-color: #212121;
        color: white;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: #ff4500;
            transition: all .2s;
        }
    }
}

@media screen and (min-width: 1024px) {
    .fundo {
        display: block;
    }

    .conteiner-carrinho {
        width: 450px;
    }

    .finalizar-venda {
        width: 100%;
    }
}
</style>
