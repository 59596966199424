const store = {
    namespaced: true,
    state: {
        sidebarAberto: false,
    },
    mutations: {
        setSidebarAberto(state, status) {
            state.sidebarAberto = status;
        }
    },
    actions: {
        abrirSidebar({ commit }) {
            commit('setSidebarAberto', true);
        }, 
        fecharSidebar({ commit }) {
            commit('setSidebarAberto', false);
        },
        toggleSidebar({ commit, state }) {
            commit('setSidebarAberto', !state.sidebarAberto);
        }
    }
};

export default { store }