<template>
    <BaseModalBackdrop>
        <BaseModalConteiner :no-fixed-width="noFixedWidth">
            <header class="modal-header">
                <h2>
                    <slot name="title"></slot>
                </h2>

                <section>
                    <slot name="other"></slot>

                    <div class="opcoes">
                        <BaseBotaoAcao :tema="'verde'" @handleClick="$emit('imprimirMovimento')">
                            <template #icon>print</template>
                            <span class="texto_btn">Imprimir</span>
                        </BaseBotaoAcao>

                        <BaseBotaoAcao @handleClick="$emit('abrirDuplicarOrcamento', 'duplicar')">
                            <template #icon>file_copy</template>
                            <span class="texto_btn">Duplicar</span>
                        </BaseBotaoAcao>

                        <BaseBotaoAcao v-if="!(orcamento.TIPO === 'Pedido' || orcamento.TIPO === 'Pedido confirmado' || orcamento.CANCELADO === 'T')" :tema="'laranja'" @handleClick="$emit('abrirDuplicarOrcamento', 'editar')">
                            <template #icon>edit</template>
                            <span class="texto_btn">Editar</span>
                        </BaseBotaoAcao>
                    </div>
                    <div class="fechar-modal">
                        <BaseBotaoAcao :tema="'branco'" @handleClick="$emit('fecharModal')">
                            <template #icon>close</template>
                            <span class="texto_btn">Fechar</span>
                        </BaseBotaoAcao>
                    </div>
                </section>
            </header>

            <section class="modal-body">
                <slot name="body">
                    Body
                </slot>
            </section>

            <footer class="modal-footer">
                <slot name="footer"></slot>
            </footer>
        </BaseModalConteiner>
    </BaseModalBackdrop>
</template>

<script>
import BaseModalBackdrop from './BaseModalBackdrop.vue';
import BaseModalConteiner from './BaseModalConteiner.vue';
import BaseBotaoAcao from '@/components/BaseBotaoAcao.vue';

export default {
    components: {
        BaseModalBackdrop,
        BaseModalConteiner,
        BaseBotaoAcao
    },
    props: {
        noFixedWidth: {
            type: Boolean,
            default: false,
        },
        orcamento: {
            type: Object
        }
    }
}
</script>

<style lang='scss' scoped>
@import '../../../assets/styles/vars';
$border-radius: 4px;

.modal-header {
    padding: 10px;
    background-color: $branco;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & section {
        margin-top: .5rem;

        display: flex;
        align-items: center;
    }

    & h2 {
        font-weight: 500;
        font-size: 1.2rem;
    }
}

.fechar-modal {
    margin-left: .5rem;
}

.modal-body {
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.modal-footer {
    padding: 0px;
    background-color: $branco;
}

.opcoes {
    float: right;
    padding: 10px;

    display: flex;
    align-items: center;
    gap: .5rem;
}

@media screen and (min-width: 540px) {
    .modal-header {
        flex-direction: row;
    }

    .fechar-modal {
        margin-left: 1rem;
    }
  }

  @media screen and (max-width: 540px) {

    .texto_btn{
        display: none;
    }
  }

</style>
