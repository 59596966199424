export default {
    store: {
        namespaced: true,
        state: {
            usuariosAlocados: false,
            modulos: {},
            graficos: [],
            blocos: [],
        },
        mutations: {
            setUsuariosAlocados(state, usuariosAlocados) {
                state.usuariosAlocados = usuariosAlocados;
            },
            setModulos(state, modulos) {
                state.modulos = modulos;
            },
            setGraficos(state, graficos) {
                state.graficos = graficos;
            },
            setBlocos(state, blocos) {
                state.blocos = blocos;
            }
        },
        actions: {
            setUsuariosAlocados({ commit }, usuariosAlocados) {
                commit('setUsuariosAlocados', usuariosAlocados);
            },
            setModulos({ commit }, modulos) {
                commit('setModulos', modulos);
            },
            setGraficos({ commit }, graficos) {
                commit('setGraficos', graficos);
            },
            setBlocos({ commit }, blocos) {
                commit('setBlocos', blocos);
            }
        }
    }
}
