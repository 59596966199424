function transformaUndefinedParaVazio(arg) {
    return arg || '';
}

function replaceCaracteresEmNumeros(valores) {
    if(Array.isArray(valores)) {
        return casoValorParaSubstituirSejaArray(valores);
    } else if (typeof valores === 'string') {
        return casoValorParaSubstituirSejaString(valores);
    } else {
        return valores;
    }
}

function casoValorParaSubstituirSejaArray(valores) {
    for(let i = 0; i < valores.length; i++) {
        let valor = substituirEConverter(valores[i]);
        if (isNaN(valor)) {
            throw new TypeError(`Não foi possível formatar string "${valores[i]}" em array`);
        }
        valores[i] = valor;
    }
    return valores;
}

function casoValorParaSubstituirSejaString(valor) {
    if (valor.length === 0) {
        return valor;
    }
    
    let num = substituirEConverter(valor);
    if (isNaN(num)) {
        throw new TypeError(`Não foi possível formatar string "${valor}"`);
    }
    return num;
}

function substituirEConverter(num) {
    num = substituirCaracteres(num);
    num = parseFloat(num);
    return num;
}

function substituirCaracteres(valor) {
    if (valor.includes('.') && !valor.includes(',')) {
        // é o caso de strings tipo "10.0" ou "2.25"
        return valor;
    } else {
        return valor
            .split('R$').join('')
            .split('.').join('')
            .split(',').join('.');
    }
}

function formatarFieldToData(data) {
    const d = new Date(data);

    const dia = d.getDate().toString();
    const diaF = (dia.length === 1) ? '0' + dia : dia;
    const mes = (d.getMonth() + 1).toString();
    const mesF = (mes.length === 1) ? '0' + mes : mes;
    const anoF = d.getFullYear();

    return `${diaF}/${mesF}/${anoF}`;
}

function formatarDataToField(data) {
    let d;
    if(typeof data === 'object') {
        d = data;
    } else {
        d = new Date(data.split('/').reverse().join('-'));
        d.setDate(d.getDate() + 1);
    }

    const dd = d.getDate();
    const mm = d.getMonth() + 1;
    const yy = d.getFullYear();

    const dataCerta = new Date(`${yy}/${mm}/${dd}`);
    const day = ('0' + dataCerta.getDate()).slice(-2);
    const month = ('0' + (dataCerta.getMonth() + 1)).slice(-2);

    const today = `${dataCerta.getFullYear()}-${month}-${day}`;

    return today;
}

function tratarDataComDiaEMesTrocados(data) {
    const diaEMesSeparados = data.split('/');
    const dia = diaEMesSeparados[1];
    const mes = diaEMesSeparados[0];
    const restoDaData = diaEMesSeparados[2];
    const diaEMesTrocados = `${dia}/${mes}/${restoDaData}`;

    return diaEMesTrocados;
}

function tratarDataInversa(data) {
    const valorCortado = data.split('-');
    const dia = valorCortado[2];
    const mes = valorCortado[1];
    const ano = valorCortado[0];

    const dataTratada = `${dia}/${mes}/${ano}`;
    return dataTratada
}

function retornarNumberEmMonetario(num) {
    return num.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export default {
    transformaUndefinedParaVazio,
    replaceCaracteresEmNumeros,
    formatarDataToField,
    formatarFieldToData,
    tratarDataComDiaEMesTrocados,
    retornarNumberEmMonetario,
    tratarDataInversa
}
