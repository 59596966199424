export default {
    store: {
        namespaced: true,
        state: {
            dashboards: [],
        },
        mutations: {
            setDashboards(state, d) {
                state.dashboards = d;
            }
        },
        actions: {
            setDashboards({ commit }, d) {
                commit('setDashboards', d);
            }
        }
    }
}
