<template>
    <div class="tabela-wrapper">
        <table class="tabela-consulta itens_table_desc">
            <thead class="tabela-consulta__header">
                <tr>
                    <th v-for="(label, i) in labels" :key="i">{{ label }}</th>

                    <th v-for="action in actions" :key="action.icon"></th>
                </tr>
            </thead>

            <tbody class="tabela-consulta__conteudo">
                <tr v-for="(item, i) in itens"
                    :class="[((item.CANCELADO && item.CANCELADO === $store.state.orcamento.ITEM_CANCELADO) || (item.cancelado && item.cancelado === $store.state.orcamento.ITEM_CANCELADO) || (item.DISPONIVEL && valorEhInvalido(item.DISPONIVEL) && this.saldoNegativo === 'F'))
                        ? 'movimento-cancelado'
                        : '', modo === 'rotas' && item.MOVIMENTONODIA ? item.MOVIMENTONODIA === 'SIM' ? 'confirmado' : item.MOVIMENTONODIA === 'OBS' ? 'negado' : '' : '']"
                    :key="i" @click.left="clicarEmRow(item, i)">

                    <td v-if="modo === 'material'" @click="abrirModal(item, $event)">
                        <img class="imgProduto" :src="listaImagens[i]" alt="imagemWeb">
                    </td>

                    <td v-for="(attr, i) in attrs" :key="i" :class="colocarNumeroADireita(item[attr])">
                        {{ retornarAtributo(item, attr) }}
                    </td>

                    <td 
                        v-for="action in actions" 
                        :key="action.icon" 
                        :title="action.meta ? action.meta.title : ''"
                        @click.stop="() => action.fn(item, i)">
                        <div style="position: relative;">
                            <i class="material-icons-sharp">{{ action.icon }}</i>

                            <div v-if="action.dropdown && i === action.itemAberto">
                                <div class="detalhe-dropdown" />
                                <article class="dropdown-itens-table">
                                    <div 
                                        class="opcao" 
                                        v-for="di in action.itens" 
                                        :key="di.label"
                                        @click="di.fn(item, i)">
                                        <span>{{ di.label }}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <ModalVisualisarImagem v-if="modalImagemAberto" @fecharModal="fecharModal" :itemSelecionado="itemSelecionado" />

        <ModalConfirmarAcao v-if="modalConfirmarAberto" :item="itemModal" @confirmarAcao="emitRota"
            @fecharModal="fecharModalConfirmar">
            <template #mensagem>Este cliente já possui um movimento realizado neste dia. Deseja realizar um novo
                movimento
                mesmo assim?</template>
        </ModalConfirmarAcao>

    </div>
</template>

<script>
import fmt from '@/lib/utils/formatacao';
import sessao from '@/lib/utils/sessao';
import ModalVisualisarImagem from './tiraPedido/itens/modais/ModalVisualisarImagem.vue';
import ModalConfirmarAcao from './modais/ModalConfirmarAcao.vue';
import BaseMiniModal from './modais/templates/BaseMiniModal.vue';
import BaseDropdown from './BaseDropdown.vue';
import { Dropdown } from 'bootstrap';

export default {
    components: {
        ModalVisualisarImagem,
        ModalConfirmarAcao,
        BaseMiniModal,
        BaseDropdown
    },
    emits: [
        'clicarEmRow',
    ],
    props: {
        itens: {
            type: Array,
            required: true,
        },
        labels: {
            type: Array,
            required: true,
        },
        attrs: {
            type: Array,
            required: true,
        },
        actions: {
            type: Array,
            required: false,
        },
        listaImagens: {
            type: Array,
            required: false
        },
        modo: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            permiteImagem: sessao.getItemSessao('PERMITEIMAGEM'),
            itemSelecionado: {},
            modalImagemAberto: false,
            modalConfirmarAberto: false,
            itemModal: '',
            saldoNegativo: sessao.getItemSessao('SALDONEGATIVO')
        }
    },
    methods: {
        retornarAtributo(item, attr) {
            if (attr.type && attr.type === 'fn') {
                return item[attr.caller]();
            } else if (attr instanceof Array) {
                let atributoComposto = '';

                for (let a of attr) {
                    let naoIndefinido = fmt.transformaUndefinedParaVazio(item[a]);
                    atributoComposto += naoIndefinido + ' ';
                }
                return atributoComposto;
            } else {
                let valor = fmt.transformaUndefinedParaVazio(item[attr]);
                if (typeof valor === 'number') {
                    valor = fmt.retornarNumberEmMonetario(valor);
                }
                return valor;
            }
        },
        colocarNumeroADireita(valor) {
            try {
                if (typeof valor === 'string') {
                    const formatado = fmt.replaceCaracteresEmNumeros(valor);
                    if (typeof formatado === 'number')
                        return 'numero-a-direita';
                    else
                        return 'valor-centrado';
                } else {
                    return 'numero-a-direita';
                }
            } catch (error) {
                return 'valor-centrado';
            }
        },
        clicarEmRow(item, row) {
            if (this.modo === 'rotas' && item.MOVIMENTONODIA === 'SIM') {
                this.itemModal = item
                this.modalConfirmarAberto = true
            } else if (this.clicarEmRow) {
                this.$emit('clicarEmRow', item, row);
            }
        },
        abrirModal(item, event) {
            if (this.modo === 'material' && this.permiteImagem === 'T') {
                event.stopPropagation();
                this.itemSelecionado = item
                this.modalImage.Aberto = true
            }
        },
        fecharModal() {
            this.modalImagemAberto = false
        },
        valorEhInvalido(qtd) {
            return fmt.replaceCaracteresEmNumeros(qtd) <= 0;
        },
        abrirModalConfirmar() {
            this.modalConfirmarAberto = true
        },
        fecharModalConfirmar() {
            this.modalConfirmarAberto = false
        },
        emitRota(item) {
            this.$emit('clicarEmRow', item);
        }
    },
}
</script>

<style lang="scss" scoped>
.confirmado {
    background-color: rgba(172, 248, 181, 0.81)
}

.confirmado:hover {
    background-color: rgba(117, 253, 133, 0.81) !important
}

.confirmado:nth-child(odd) {
    background-color: rgba(148, 245, 159, 0.81) !important;
}

.confirmado:nth-child(odd):hover {
    background-color: rgba(117, 253, 133, 0.81) !important
}

.negado {
    background-color: rgba(255, 160, 160, 0.81)
}

.negado:hover {
    background-color: rgba(240, 80, 80, 0.81) !important
}

.negado:nth-child(odd) {
    background-color: rgba(255, 126, 126, 0.81) !important;
}

.negado:nth-child(odd):hover {
    background-color: rgba(240, 80, 80, 0.81) !important
}

.dropdown-itens-table {
    position: absolute;
    right: 1.2rem;
    top: -25px;
    z-index: 1;
    margin: 5px;
    min-width: 90px;
    background-color: #f0f0f0;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 21%);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border: 2px solid #ababab;
    padding: 5px;
    border-radius: 3px;
}

.detalhe-dropdown {
    position: absolute;
    top: 0px;
    left: -4%;
    width: 0px;
    height: 0px;
    background-color: rgba(232, 93, 55, 0);
    z-index: 2;
    border-radius: 2px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #ababab;
}

.opcao:hover {
    background-color: rgb(209, 209, 209);
    cursor: pointer;
}
</style>
