<template>
    <BaseModalBackdrop>
        <BaseModalConteiner :no-fixed-width="noFixedWidth">
            <header class="modal-header">
                <h2>
                    <slot name="title"></slot>
                </h2>

                <section>
                    <slot name="other"></slot>

                    <div class="fechar-modal">
                        <BaseBotaoAcao
                            :tema="'branco'"
                            @handleClick="$emit('fecharModal')">
                            <template #icon>close</template>
                            Fechar
                        </BaseBotaoAcao>
                    </div>
                </section>
            </header>

            <section class="modal-body">
                <slot name="body">
                    Body
                </slot>
            </section>

            <footer class="modal-footer">
                <slot name="footer"></slot>
            </footer>
        </BaseModalConteiner>
    </BaseModalBackdrop>
</template>

<script>
import BaseModalBackdrop from './BaseModalBackdrop.vue';
import BaseModalConteiner from './BaseModalConteiner.vue';
import BaseBotaoAcao from '@/components/BaseBotaoAcao.vue';

export default {
    components: {
        BaseModalBackdrop,
        BaseModalConteiner,
        BaseBotaoAcao
    },
    props: {
        noFixedWidth: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang='scss' scoped>
@import '../../../assets/styles/vars';
$border-radius: 4px;

.modal-header {
    padding: 2px 10px;
    background-color: $branco;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & section {
        margin-top: .5rem;

        display: flex;
        align-items: center;
    }

    & h2 {
        font-weight: 500;
        font-size: 1.2rem;
    }
}

.fechar-modal {
    margin-left: .5rem;
}

.modal-body {
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.modal-footer {
    padding: 0px;
    background-color: $branco;
    border-radius: $border-radius;
}

@media screen and (min-width: 540px) {
    .modal-header {
        flex-direction: row;
    }

    .fechar-modal {
        margin-left: 1rem;
    }
}
</style>
