import sessao from '../lib/utils/sessao.js';
import fmt from '../lib/utils/formatacao.js';
import incit from '@/lib/inclusaoItens.js';
import { EstoqueExcedidoError } from '@/lib/errors/errors.js';

const getDefaultState = () => {
    return {
        ITEM_NAO_CANCELADO: 'F',
        ITEM_CANCELADO: 'T',
        ordemOrcamento: '',
        cliente: {},
        vendedor: {},
        tabelaPreco: {},
        centroCusto: {},
        itens: [],
        formaPagamento: {},
        tiposPagamento: [],
        totalBruto: 0,
        subTotal: 0,
        totalLiquido: 0,
        desconto: 0,
        somaDescontoItens: 0,
        observacao: '',
        modoOrcamento: '',
        dadosPDF: {},
        permissoes: {},
        saldoNegativo: 'F',
        localizacao: 'F',
        alteraConsulta: 'F',
        modoIndustria: 'F',
        formaIndustria: 'F',
        tipoIndustria: 'F',
        centroCustoAtivo: 'F',
        pastaImagem: 'F',
        tabelaItens: '',
        itensPesquisa: [],
        tabelaPrecoAtual: '',
        usaTabelaPreco: 'F',
        focusedRow: null,
        solicitarAdministrador: {
            descontoGeral: false,
        },
        codigoPadraoItens: 'material'
    }
}

const store = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        salvarCliente(state, cliente) {
            state.cliente = cliente;
        },
        salvarLimiteCreditoCliente(state, limite) {
            Object.assign(state.cliente, limite);
        },
        salvarVendedor(state, vendedor) {
            state.vendedor = vendedor;
        },
        salvarTabelaPreco(state, tabelaPreco) {
            state.tabelaPreco = tabelaPreco;
        },
        salvarCentroCusto(state, centro) {
            state.centroCusto = centro;
        },
        incluirItem(state, item) {
            state.itens.push(item);
        },
        iniciarPedido(state, ordem) {
            state.ordemOrcamento = ordem;
        },
        preencherFormaPagamento(state, forma) {
            state.formaPagamento = forma;
        },
        salvarUsaTabelaPreco(state, usaTabela){
            state.usaTabelaPreco = usaTabela
        },
        adicionarTipoPagamento(state, tipo) {
            state.tiposPagamento.push(tipo);
        },
        preencherTipoPagamento(state, { tipo, index }) {
            state.tiposPagamento[index] = tipo;
        },
        limparTiposPagamento(state) {
            state.tiposPagamento.length = 0;
        },
        apagarTipoPagamento(state, index) {
            state.tiposPagamento.splice(index, 1);
        },
        salvarTotalBruto(state, total) {
            state.totalBruto = total
        },
        salvarSubTotal(state, total) {
            state.subTotal = total;
        },
        salvarTotalLiquido(state, total) {
            state.totalLiquido = total;
        },
        salvarDesconto(state, desconto) {
            state.desconto = desconto;
        },
        salvarSomaDescontoItens(state, soma) {
            state.somaDescontoItens = soma
        },
        salvarObservacao(state, obs) {
            state.observacao = obs
        },
        salvarModoOrcamento(state, modo) {
            state.modoOrcamento = modo;
        },
        salvarPDF(state, pdf) {
            state.dadosPDF = pdf;
        },
        salvarPermissoes(state, per) {
            state.permissoes = per
        },
        salvarSaldoNegativo(state, saldo) {
            state.saldoNegativo = saldo
        },
        salvarLocalizacao(state, localizacao) {
            state.localizacao = localizacao
        },
        salvarModoIndustria(state, modo) {
            state.modoIndustria = modo
        },
        salvarFormaIndustria(state, forma) {
            state.modoIndustria = forma
        },
        salvarTipoIndustria(state, tipo) {
            state.modoIndustria = tipo
        },
        salvarCentroCustoAtivo(state, centro) {
            state.centroCustoAtivo = centro;
        },
        salvarImagem(state, imagem) {
            state.pastaImagem = imagem;
        },
        salvarTabelaItens(state, tabela) {
            state.tabelaItens = tabela;
        },
        salvarItensPesquisa(state, itens) {
            state.itensPesquisa = itens;
        },
        salvarTabelaAtual(state, atual) {
            state.tabelaPrecoAtual = atual;
        },
        salvarLinhaFocada(state, linha){
            state.focusedRow = linha
        },
        solicitarAdministrador(state, { solicitar, valor }) {
            state.solicitarAdministrador[solicitar] = valor;
        },
        salvarCodigoPadrao(state, codigo) {
            state.codigoPadraoItens = codigo
        }
    },
    actions: {
        salvarCliente({ commit }, cliente) {
            sessao.addEmStorage('CLIENTE', cliente);
            commit('salvarCliente', cliente);
        },
        salvarLimiteCreditoCliente({ commit }, limite) {
            commit('salvarLimiteCreditoCliente', limite);
        },
        salvarVendedor({ commit }, vendedor) {
            sessao.addEmSessao('VENDEDOR', vendedor);
            commit('salvarVendedor', vendedor);
        },
        salvarTabelaPreco({ commit }, tabelaPreco) {
            sessao.addEmSessao('TABELAPRECO', tabelaPreco);
            commit('salvarTabelaPreco', tabelaPreco);
        },
        salvarCentroCusto({ commit }, centro) {
            sessao.addEmSessao('CENTROCUSTO', centro);
            commit('salvarCentroCusto', centro);
        },
        async incluirItem({ commit, state, dispatch }, item) {
            const validarItemAntesDeInclusao = async () => {
                const precoNegativo = sessao.getItemSessao('SALDONEGATIVO');

                if (!item.CODIGOMATERIAL || !item.DESCRICAO || !item.UNITARIO)
                    throw new Error('Nenhum item foi selecionado');
                if (!Number.isInteger(item.QUANTIDADE) && item.NPERMITEQUANTFRACIONADA === 'T')
                    throw new Error('O tipo de unidade não permite números decimais');

                if (precoNegativo === 'F') {
                    if (itemIncluso) {
                        if (item.CODIGOALMOXARIFADO !== itemIncluso.CODIGOALMOXARIFADO) {
                            incit.mesclarAlmoxarifados([itemIncluso, item], itemIncluso);
                        } else if (item.QUANTIDADE > itemIncluso.DISPONIVEL) {
                            throw new Error('Cancele esse item na tabela principal antes de adicioná-lo novamente.');
                        }
                    } else {
                        if (!item.ALMOXARIFADOS && item.QUANTIDADE > item.DISPONIVEL) {
                            throw new EstoqueExcedidoError('Quantidade maior que quantidade disponível');
                        }
                    }
                }

                // Qualquer alteração de preço deve ser validada,
                // exceto se a solicitação ao administrador estiver ativa
                if (!item.SOLICITARADMINISTRADOR) {
                    await validarAlteracoesPreco();
                }
            };

            const validarAlteracoesPreco = async () => {
                const permissoes = await dispatch('tratarPermissoes');
                const valorMinimo = Math.round(((1 - (permissoes.DESCONTOMAXPRECOGERAL / 100)) * item.UNITARIOFIXO) * 100) / 100;
                const valorMaximo = Math.round(((1 + (permissoes.MAIOR / 100)) * item.UNITARIOFIXO) * 100) / 100;

                if (item.UNITARIO > valorMaximo) {
                    item.UNITARIO = item.UNITARIOFIXO;
                    throw new Error(`O maior preço permitido é de R$${valorMaximo.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`);
                } else if (item.UNITARIO < valorMinimo) {
                    item.UNITARIO = item.UNITARIOFIXO;
                    throw new Error(`O menor preço permitido é de R$${valorMinimo.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`);
                } else if ((item.UNITARIO < item.UNITARIOFIXO) && ([item.DESCONTOPORCENTO, item.DESCONTOREAL].includes(0))) {
                    throw new Error('Não é possível incluir valor abaixo do preço original.');
                }
            };

            const salvarItem = () => {
                if (itemIncluso) {
                    editarIncluso();
                } else {
                    criarNovo();
                }
                sessao.addEmStorage('ITENS', state.itens);
            };

            const editarIncluso = () => {
                // apenas os parâmetros que podem ser editados
                itemIncluso.QUANTIDADE = item.QUANTIDADE;
                itemIncluso.PRECOVENDA = item.PRECOVENDA;
                itemIncluso.UNITARIOFIXO = item.PRECOVENDA;
                itemIncluso.UNITARIO = item.UNITARIO;
                itemIncluso.DESCONTOREAL = item.DESCONTOREAL;
                itemIncluso.DESCONTOPORCENTO = item.DESCONTOPORCENTO;
                itemIncluso.TIPOENTREGA = item.TIPOENTREGA;
                itemIncluso.SOLICITARADMINISTRADOR = item.SOLICITARADMINISTRADOR;

                incit.mudarCasoPromocional(itemIncluso);
                itemIncluso.TOTAL = itemIncluso.QUANTIDADE * itemIncluso.UNITARIO;
            };

            const criarNovo = () => {
                item.ID = Date.now().toString();
                item.CANCELADO = state.ITEM_NAO_CANCELADO;

                if (item.UNITARIOMAXIMO > item.UNITARIOFIXO)
                    item.UNITARIOFIXO = item.UNITARIOMAXIMO;

                commit('incluirItem', item);
            };

            let itemIncluso = null;
            if (item.ID) {
                itemIncluso = state.itens.find(el => el.ID === item.ID);
            }
            await validarItemAntesDeInclusao();
            salvarItem();
        },
        tratarPermissoes() {
            const permissaoDesconto = sessao.getItemSessao('PERMISSOES');
            for (let itens in permissaoDesconto) {
                let valor = permissaoDesconto[itens];

                valor = valor.replace(',', '.');
                valor = parseFloat(valor);
                permissaoDesconto[itens] = valor;
            }
            return permissaoDesconto;
        },
        setItemSessao({ commit, state }, item) {
            commit('incluirItem', item);
            sessao.addEmStorage('ITENS', state.itens);
        },
        iniciarPedido({ commit }, ordem) {
            commit('iniciarPedido', ordem);
        },
        preencherFormaPagamento({ commit }, forma) {
            commit('preencherFormaPagamento', forma);
        },
        adicionarTipoPagamento({ commit, state }, payload) {
            if (!payload)
                payload = {};
            else if (payload.extra && payload.extra === true)
                payload = {
                    DIA: 0,
                    CODIGOTIPOEVENTO: "",
                    DEFASAGEM: null,
                    NUMEROPARCELA: state.tiposPagamento.length,
                    PERCENTUAL: 100,
                    DATAVENCIMENTO: new Date().toISOString().split('T')[0],
                    DESCRICAO: "",
                    VALORPARCELA: 0,
                };
            commit('adicionarTipoPagamento', payload);
        },
        preencherTipoPagamento({ commit }, { tipo, index }) {
            commit('preencherTipoPagamento', {
                tipo,
                index,
            });
        },
        limparTiposPagamento({ commit }) {
            commit('limparTiposPagamento');
        },
        apagarTipoPagamento({ commit }, index) {
            commit('apagarTipoPagamento', index);
        },
        salvarTotalBruto({ commit }, total) {
            commit('salvarTotalBruto', total);
        },
        salvarSubTotal({ commit }, total) {
            commit('salvarSubTotal', total);
        },
        salvarTotalLiquido({ commit }, total) {
            commit('salvarTotalLiquido', total);
        },
        salvarDesconto({ commit }, desconto) {
            commit('salvarDesconto', desconto);
        },
        salvarSomaDescontoItens({ commit }, soma) {
            commit('salvarSomaDescontoItens', soma);
        },
        salvarObservacao({ commit }, obs) {
            commit('salvarObservacao', obs);
        },
        salvarModoOrcamento({ commit }, modo) {
            commit('salvarModoOrcamento', modo);
        },
        salvarPDF({ commit }, pdf) {
            sessao.addEmSessao('PDF', pdf);
            commit('salvarPDF', pdf);
        },
        salvarPermissoes({ commit }, per) {
            sessao.addEmSessao('PERMISSOES', per);
            commit('salvarPermissoes', per)
        },
        salvarSaldoNegativo({ commit }, saldo) {
            sessao.addEmSessao('SALDONEGATIVO', saldo);
            commit('salvarSaldoNegativo', saldo)
        },
        salvarLocalizacao({ commit }, localizacao) {
            sessao.addEmSessao('LOCALIZACAO', localizacao);
            commit('salvarLocalizacao', localizacao)
        },
        salvarModoIndustria({ commit }, modo) {
            sessao.addEmSessao('MODOINDUSTRIA', modo);
            commit('salvarModoIndustria', modo)
        },
        salvarFormaIndustria({ commit }, forma) {
            sessao.addEmSessao('FORMAINDUSTRIA', forma);
            commit('salvarFormaIndustria', forma)
        },
        salvarTipoIndustria({ commit }, tipo) {
            sessao.addEmSessao('TIPOINDUSTRIA', tipo);
            commit('salvarTipoIndustria', tipo)
        },
        salvarCentroCustoAtivo({ commit }, centro) {
            sessao.addEmSessao('CENTROCUSTOATIVO', centro);
            commit('salvarCentroCustoAtivo', centro);
        },
        salvarImagem({ commit }, imagem) {
            sessao.addEmSessao('PERMITEIMAGEM', imagem);
            commit('salvarImagem', imagem);
        },
        salvarItensPesquisa({ commit, state }, itens) {
            commit('salvarTabelaAtual', state.tabelaPreco.CODIGOTABELAPRECO);
            commit('salvarTabelaItens', state.cliente.CODIGOPARCEIRO);
            commit('salvarItensPesquisa', itens);
        },
        salvarUsaTabelaPreco({commit}, usaTabela) {
            sessao.addEmSessao('USATABELAPRECO', usaTabela)
            commit('salvarUsaTabelaPreco', usaTabela )
        },
        salvarLinhaFocada({commit}, linha){
            commit('salvarLinhaFocada', linha)
        },
        solicitarAdministrador({ commit }, payload) {
            commit('solicitarAdministrador', payload);
        },
        salvarCodigoPadrao({ commit }, codigo) {
            commit('salvarCodigoPadrao', codigo);
        }
    },
    getters: {
        itensInclusos(state) {
            return state.itens.filter(itemAtual => {
                if (itemAtual.CANCELADO === state.ITEM_NAO_CANCELADO) {
                    return itemAtual;
                } else {
                    return;
                }
            });
        },
        subTotal(state) {
            return fmt.retornarNumberEmMonetario(state.subTotal);
        },
        totalLiquido(state) {
            return fmt.retornarNumberEmMonetario(state.totalLiquido);
        }
    }
}

export default {
    store,
    getDefaultState
}
